import {
    Box,
    Typography,
    styled,
    Dialog,
    makeStyles,
    useTheme,
} from '@material-ui/core'
import { Button } from 'components'
import {
    Loader,
    Pagination,
    Select,
    ToggleSwitch,
    Tooltip,
} from 'packages/eid-ui'
import { ArrowSmallIcon, Icon } from 'packages/eid-icons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import MemberIAMShopAssigment from '../IAMShopAssignments/MemberIAMShopAssigment'
import Search from 'components/Filters/Search'
import {
    useAccessRequestPolicy,
    useAddItemsToCart,
    useCart,
    useConfigSettings,
    useIAmShopAssignees,
    useModalSearch,
} from 'hooks'
import { isNilOrEmpty, useDebounce } from 'packages/core'
import classNames from 'classnames'
import cartHelpers from 'containers/Cart/cartHelpers'
import { PageContextProvider, usePageContext } from 'pageContext'
import { paginate } from 'utils'
import { EndDateTimePicker } from 'components/TimeConstrainedControl/EndDateTimePicker'
import { StartDateTimePicker } from 'components/TimeConstrainedControl/StartDateTimePicker'
import PolicyDetails from 'components/PolicyDetails'
import { useAppState } from 'appContext'
import config from 'config'

export const CloseIconeBox = styled(Box)({
    '& svg': {
        position: 'absolute',
        top: '-24px',
        right: '-24px',
        cursor: 'pointer',
    },
})

const AssignmentsModal = styled(Box)({
    maxWidth: '700px',
    width: '100%',
    background: '#fff !important',
    border: 'solid 1px #ebebed',

    '& h2': {
        fontSize: '24px',
        color: '#282828',
    },
})

export const Divider = styled(Box)({
    background: '#d8d8d8 !important',
    height: '1px',
    margin: '24px 0',
})
export const InputBox = styled(Box)({
    '& input': {
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        height: '30px',
        width: '100%',
        borderRadius: '5px',
        outline: 'none',
        padding: '5px 15px',
    },
    '& .MuiSelect-select.MuiSelect-select': {
        width: '100%',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: 'solid 1px #ebebed',
        height: '30px',
        borderRadius: '5px',
        outline: 'none',
        padding: '5px 15px',
        background: '#fff !important',
    },

    '& .MuiInputBase-fullWidth': {
        maxWidth: '360px',
    },
    '& MuiSelect-selectMenu': {
        fontSize: '14px',
    },

    '& .MuiInput-underline': {
        '&:after': {
            border: 'none !important',
        },
        '&:before': {
            border: 'none !important',
        },
    },
})
export const ModalFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    '& button': {
        border: `solid 1px ${theme?.palette?.primary?.main}`,
        padding: '4px 16px 4px 13px',
        width: '100%',
        borderRadius: '4px',
        outline: 'none',
        height: '32px',
        fontSize: '14px',
        '& svg': {
            marginLeft: '8px',
        },
        '&:nth-child(1)': {
            background: theme?.palette?.primary?.main,
            color: '#fff',
            maxWidth: '240px',
        },
        '&:nth-child(2)': {
            color: theme?.palette?.primary?.main,
            marginLeft: '16px',
            maxWidth: '90px',
        },
    },
}))

export const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        maxWidth: '830px',
        width: '100%',
        background: '#f7f8fa',
        '& .MuiBox-root': {
            maxWidth: '830px',
            width: '100%',
        },
    },
    '& .MuiBox-root': {
        border: 'none',
        background: ' #f7f8fa',
        position: 'relative',
    },
})

const useStyles = makeStyles((theme) => ({
    headerBox: {
        padding: '40px 40px 0px 40px',
        backgroundColor: '#ffffff !important',
    },
    bodyBox: {
        padding: '24px 40px 40px 40px',
    },
    ActiceSection: {
        background: 'rgba(48, 127, 193, 0.07)',
        borderLeft: `2px solid ${theme?.palette?.primary?.main}`,
    },

    ButtonRemoveSection: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        '& button': {
            display: 'flex',
            justifyContent: 'center',
            height: 'fit-content !important',
            gap: '10px',
            borderRadius: '5px',
            '& label': {
                maxWidth: '85%',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                TextOverflow: 'ellipsis',
                wordWrap: 'break-word',
                cursor: 'pointer',
            },
        },
    },
    SearchPeople: {
        '& p': {
            fontSize: '16px',
            marginBottom: '16px',
        },
        '& svg': {
            marginLeft: '0 !important',
        },
    },
    TimeLabelSection: {
        display: 'flex',
        maxWidth: '100%',
        '& .MuiBox-root': {
            maxWidth: 'fit-content !important',
        },
    },
    timeConstraintSwitchButton: {
        backgroundColor: '#fbfbfc',
        color: '#4a4a4a !important',
        border: 'solid 1px #b2b5bb',
        borderRadius: '5px',
        padding: '16px',
        minHeight: '56px',
        '&:hover': {
            backgroundColor: '#fbfbfc',
            border: 'solid 1px #265fb4',
        },
        '& span': {
            fontWeight: 'bold',
        },
    },
    activeTimeConstraintSwitchButton: {
        color: '#000000 !important',
        border: 'solid 1px #265fb4',
        '&:hover': {
            color: '#000000 !important',
        },
    },
    disabledTimeConstraintSwitchButton: {
        color: 'rgba(0, 0, 0, 0.26) !important',
    },
    searchBar: {
        width: '260px !important',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
        '& div': {
            borderRadius: '4px',
        },
    },
    inactiveButtonForAdd: {
        border: 'solid 1px #8b909a',
        color: '#8b909a',
        marginTop: '60px',
        marginBottom: '20px',
        '& svg': {
            width: '12px',
            position: 'absolute',
            right: '5px',
        },
    },
    activeButtonForAdd: {
        backgroundColor: '#01ae8f',
        color: '#fff !important',
        borderColor: '#01ae8f !important ',
        '&:hover': {
            backgroundColor: '#01ae8fcc !important',
        },
        '& svg': {
            color: '#fff !important',
            fill: '#fff !important',
        },
    },

    inactiveButtonForRemove: {
        border: 'solid 1px #8b909a',
        color: '#8b909a',
        marginBottom: '20px',
        '& svg': {
            width: '12px',
            position: 'absolute',
            left: '5px',
        },
    },
    activeButtonForRemove: {
        backgroundColor: '#ff4d5f',
        color: '#fff !important',
        borderColor: '#ff4d5f !important ',
        '&:hover': {
            backgroundColor: '#ff4d5fcc !important',
        },
        '& svg': {
            color: '#fff !important',
            fill: '#fff !important',
        },
    },

    inactiveButtonForRemoveAll: {
        border: 'solid 1px #8b909a',
        color: '#8b909a',
        '& svg': {
            width: '12px',
            position: 'absolute',
            left: '5px',
        },
    },
    activeButtonForRemoveAll: {
        border: 'solid 1px #ff4d5f !important',
        color: '#ff4d5f !important',
        '& svg': {
            color: '#ff4d5f !important',
            fill: '#ff4d5f !important',
        },
    },
    TimeSectionFooter: {
        '& button': {
            maxWidth: '100% !important',
            width: 'fit-content',
        },
    },
    SelectAssignmentSectionContainer: {
        background: '#fff !important',
        border: 'solid 1px #ebebed  !important',
        borderRadius: '5px !important',
        maxWidth: '260px !important',
    },
    SelectAssignmentTitle: {
        maxWidth: '260px !important',
        width: '100%',
        marginBottom: '10px',
    },
    SelectAssignmentTitleblank: {
        maxWidth: '225px !important',
        width: '100%',
    },
    AssignmentsModalloader: {
        background: '#fff !important',
        height: '100%',
        '& .MuiBox-root': {
            background: '#fff !important',
        },
    },
}))

const SelectAssignmentSection = styled(Box)({
    background: '#fff !important',
    maxWidth: '260px !important',
    overflow: 'hidden',
    height: '300px',
    overflowY: 'auto',
    padding: '5px',
    '&::-webkit-scrollbar ': {
        display: 'none',
    },
})
const PaginationMain = styled(Box)({
    maxWidth: '260px !important',
    width: '100%',
    marginTop: '15px',
    '& ul': {
        height: '30px',
        borderRadius: '20px',
        '& li': {
            '& button': {
                padding: '0px 2px',
            },
        },
    },
})
const ButtonSection = styled(Box)({
    marginLeft: '15px',
    marginRight: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '190px !important',
    gap: '100px',
    '& button': {
        minWidth: '125px',
        cursor: 'pointer',
        fontSize: '14px',
        borderRadius: '4px',
        height: '31px',
    },
})
//Below page size is for Client Side pagination
const pageSize = 5

export const PermissionLevels = ({
    value,
    permissionLevelProps,
    onChange,
    targetAssigneeId,
}) => {
    const { t } = useTranslation()

    const { list: accessLevels } = useIAmShopAssignees(targetAssigneeId, {
        take: 10000,
    })
    const { permissionLabelProp } = permissionLevelProps
    const getOptionLabel = (option) => option[permissionLabelProp]

    return (
        <InputBox>
            <Typography variant="subtitle1">
                {t('IAmShopPermissionLevel')}
            </Typography>
            <Select
                value={value}
                options={accessLevels?.length > 0 ? accessLevels : []}
                placeholder={t('GetPermissionLevel')}
                getOptionLabel={getOptionLabel}
                onChange={onChange}
                required
            />
        </InputBox>
    )
}
const AddAssignment = ({
    searchUrl,
    identifier = 'id',
    labelKey,
    showPermissionLevel,
    filterWithAccessLevel = false,
    targetAssigneeResource,
    targetAssigneeId,
    startIcon,
    headerText,
    queryParams = {},
    listLabels = {
        unselectedListLabel: 'People',
        selectedListLabel: 'SelectedPeople',
    },
    searchPlaceHolder = 'SearchFilterMembers',
    onSubmit,
    submitLoader,
    submitButtonLabelKey,
    hideDurationSelection = false,
    allowRemovingAssignments = false,
    permissionLevelProps = {
        permissionLabelProp: 'value',
        permissionIdetifierProp: 'id',
    },
    handleClose = null,
    assignedPeopleList = null,
    closeOnSubmit = false,
    showAssignmentModal = false,
    // NOTE: Need to refine with Shahid. Adding for now in order not to break old logic
    addSelectedOnly = false,
    secondaryAttributes = ['email'],
    resourceTypeRoleId_ConfigName,
    showAccessRequestPolicy = false,
    requestPolicyId,
    requestPolicyIdProperty = 'accessRequestPolicyId',
    fetchRequestPolicyItemWise = false,
    showTimeConstrainedFilter = false,
    showStartDate = true,
}) => {
    const { t } = useTranslation()
    const classes = useStyles({})
    const theme = useTheme()
    const [addItemsToCart, { isLoading }] = useAddItemsToCart()
    const [, dispatch] = usePageContext()
    const debouncedSearchValue = useDebounce(searchKey)
    const { data: cart } = useCart()
    const [{ devFeaturesEnabled }] = useAppState()

    const [openAssigneeModal, setOpenAssigneeModal] =
        useState(showAssignmentModal)
    const [poilicyAssignee, setPolicyAssignee] = useState(null)
    const [timeConstrained, setTimeConstrained] = useState(false)
    const [searchKey, setSearchKey] = useState('')
    const [peopleAddedList, setPeopleAddedList] = useState([])
    const [selectedAddedAssignments, setSelectedAddedAssignments] = useState([])
    const [selectedAssignments, setSelectedAssignments] = useState([])
    const [alreadyAssignedValues, setAlreadyAssignedValues] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const currentDatetime = moment.utc().second(0)

    useEffect(() => {
        if (isSubmitting && !submitLoader) {
            handleModalClose()
        }
    }, [isSubmitting, submitLoader])

    const queryData = {
        searchTerm: debouncedSearchValue,
        // passing 10000 so that we can filter out for alreadyAssigned values on first hand otherwise it will just filter from first 16
        take: allowRemovingAssignments ? 10000 : 5,
        accessLevelId:
            showPermissionLevel && filterWithAccessLevel
                ? poilicyAssignee?.id
                : undefined,
        ...queryParams,
    }

    const { list, loading, pagination } = useModalSearch(
        searchUrl,
        queryData,
        filterWithAccessLevel
            ? Boolean(poilicyAssignee?.id)
            : openAssigneeModal,
    )
    const { data: configSettings, isLoading: configSettingsLoading } =
        useConfigSettings(
            resourceTypeRoleId_ConfigName,
            Boolean(resourceTypeRoleId_ConfigName) && openAssigneeModal,
        )

    //Pagination for Left Side Items
    const [page_LeftSide, setPage_LeftSide] = useState(1)
    const filteredData_LeftSide = list?.filter(
        (el) => !peopleAddedList.some((s) => s[identifier] === el[identifier]),
    )
    const paginatedData_LeftSide =
        list && allowRemovingAssignments
            ? paginate(filteredData_LeftSide, page_LeftSide, pageSize)
            : filteredData_LeftSide

    const numberOfPages_LeftSide =
        filteredData_LeftSide && allowRemovingAssignments
            ? Math.ceil(filteredData_LeftSide?.length / pageSize)
            : 0

    //Pagination for Right side items
    const [page_RightSide, setPage_RightSide] = useState(1)
    const paginatedData_RightSide = paginate(
        peopleAddedList,
        page_RightSide,
        pageSize,
    )
    const numberOfPages_RightSide = peopleAddedList
        ? Math.ceil(peopleAddedList?.length / pageSize)
        : 0

    useEffect(() => {
        if (list && allowRemovingAssignments) {
            const _alreadyAssigned = list.filter((l) => l.isAssigned)
            if (!addSelectedOnly) {
                setAlreadyAssignedValues(_alreadyAssigned)
                setPeopleAddedList(_alreadyAssigned)
            } else {
                setAlreadyAssignedValues(
                    assignedPeopleList ? assignedPeopleList : _alreadyAssigned,
                )
                setPeopleAddedList(
                    assignedPeopleList ? assignedPeopleList : _alreadyAssigned,
                )
            }
        }
    }, [list, assignedPeopleList])

    const handleSearchChange = (keyword) => {
        setSearchKey(keyword)
    }
    const handleClear = () => {
        setSearchKey('')
    }
    const [{ selectedStartDate, selectedEndDate }, setSelectedDate] = useState({
        selectedStartDate: currentDatetime,
        selectedEndDate: null,
    })

    const allowMultipleSelection = !fetchRequestPolicyItemWise //NOTE: if have to fetch request policy of each selected item of left side, we cant allow multiple selection on left side

    const policyId = requestPolicyId
        ? requestPolicyId
        : fetchRequestPolicyItemWise
        ? selectedAssignments[0]?.[requestPolicyIdProperty]
        : ''

    const policy = useAccessRequestPolicy(
        showAccessRequestPolicy ? policyId : '',
    )

    useEffect(() => {
        if (policy?.isTimeConstrained) {
            setTimeConstrained(true)
            if (policy?.defaultValueInMinutes) {
                setSelectedDate({
                    selectedStartDate: currentDatetime,
                    selectedEndDate: moment
                        .utc()
                        .second(0)
                        .add(policy.defaultValueInMinutes, 'minutes'),
                })
            }
        }
    }, [policy])

    const violatesPolicy =
        policy?.maximumValueInMinutes &&
        moment(selectedEndDate).diff(moment(selectedStartDate), 'minutes') >
            policy?.maximumValueInMinutes

    const hasInvalidDates =
        timeConstrained &&
        (isNilOrEmpty(selectedStartDate) ||
            isNilOrEmpty(selectedEndDate) ||
            violatesPolicy ||
            moment.utc(selectedStartDate).isAfter(moment.utc(selectedEndDate)))

    let invalidDatesMessage = ''

    if (isNilOrEmpty(selectedStartDate)) {
        invalidDatesMessage = t('InvalidStartDateTime')
    }
    if (isNilOrEmpty(selectedEndDate)) {
        invalidDatesMessage = t('InvalidEndDateTime')
    }
    if (moment.utc(selectedStartDate).isAfter(moment.utc(selectedEndDate))) {
        invalidDatesMessage = t('EndDateGreaterThanStartDate')
    }
    if (violatesPolicy) {
        invalidDatesMessage = t('PolicyAllowsMaxDurationInMinError', {
            count: policy?.maximumValueInMinutes,
        })
    }

    const handleStartDate = (startDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedStartDate: startDateToSet }
        })
    }

    const handleEndDate = (endDateToSet) => {
        setSelectedDate((prev) => {
            return { ...prev, selectedEndDate: endDateToSet }
        })
    }

    const handlePolicyAssigneChange = (evt) => {
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
        setPolicyAssignee(evt)
    }

    const handleModalClose = () => {
        setOpenAssigneeModal(false)
        setSearchKey('')
        setSelectedAssignments([])
        setPeopleAddedList([])
        setSelectedAddedAssignments([])
        setPolicyAssignee(null)
        setTimeConstrained(!hideDurationSelection)
        setSelectedDate({
            selectedStartDate: currentDatetime,
            selectedEndDate: null,
        })
        dispatch({
            type: 'SET_PAGE',
            payload: 1,
        })
        if (handleClose) {
            handleClose()
        }
    }

    const checkIfSelected = (item) => {
        let selectedAttribute = {
            isSelected: false,
            alreadyAdded: false,
        }
        if (checkIfAlreadyAddedToSelectedList(item)) {
            selectedAttribute.alreadyAdded = true
        }
        if (isSelectedToAdd(item, selectedAssignments)) {
            selectedAttribute.isSelected = true
        }
        return selectedAttribute
    }

    const handleSelectPeople = (item) => {
        // Check if already added
        if (
            checkIfAlreadyAddedToSelectedList(item) ||
            checkIfAlreadyAddedToCart(item)
        ) {
            return
        }
        // If first selection add to list only
        if (!selectedAssignments?.length) {
            setSelectedAssignments([item])
            return
        }
        if (!allowMultipleSelection) {
            setSelectedAssignments([item])
            return
        }

        //  Check if item already added then update list
        setSelectedAssignments(
            addRemoveOnSelectionFromList(item, selectedAssignments),
        )
    }

    const isSelectedToAdd = (item, list) => {
        return list.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }

    const checkIfAlreadyAddedToSelectedList = (item) => {
        return peopleAddedList.find((x) => x[identifier] === item[identifier])
            ? true
            : false
    }

    const handleAddSelectedPeople = () => {
        if (selectedAssignments.length > 0) {
            selectedAssignments.forEach((x) => {
                x.timeConstrained = timeConstrained
                x.startDateUtc = timeConstrained ? selectedStartDate : null
                x.endDateUtc = timeConstrained ? selectedEndDate : null
            })

            setPeopleAddedList([...peopleAddedList, ...selectedAssignments])
            setSelectedAssignments([])
        }
    }

    const checkIfAddedSelected = (item) => {
        let selectedAttribute = {
            isSelected: false,
            alreadyAdded: false,
        }
        if (isSelectedToAdd(item, selectedAddedAssignments)) {
            selectedAttribute.isSelected = true
        }
        return selectedAttribute
    }

    const handleAddedPeopleChange = (item) => {
        if (item?.isInherited && item?.isAssigned) {
            return
        }
        setSelectedAddedAssignments(
            addRemoveOnSelectionFromList(item, selectedAddedAssignments),
        )
    }

    const addRemoveOnSelectionFromList = (item, list) => {
        let selectedValues = [...list]
        const memberIndex = selectedValues.findIndex(
            (x) => x[identifier] === item[identifier],
        )
        if (memberIndex < 0) {
            selectedValues.push(item)
        } else {
            selectedValues.splice(memberIndex, 1)
        }

        return selectedValues
    }

    const handleRemove = (actionType) => {
        if (actionType === 'all') {
            setPeopleAddedList((pre) =>
                pre.filter((i) => i.isAssigned && i.isInherited),
            )
            setSelectedAddedAssignments([])
        } else {
            const listAfterSelectedRemoved = peopleAddedList.filter((item) => {
                const isItemSelected = selectedAddedAssignments.find(
                    (x) => x[identifier] === item[identifier],
                )
                if (!isItemSelected) {
                    return item
                }
            })
            setPeopleAddedList(listAfterSelectedRemoved)
            setSelectedAddedAssignments([])
        }
    }

    const postAdd = () => {
        handleModalClose()
    }

    const preAdd = () => {
        if (peopleAddedList.length > 0) {
            let itemsToAdd = []
            peopleAddedList.forEach((item) => {
                let itemToAdd = cartHelpers.assignResourceToAssignee({
                    assignmentType: 'Add',
                    targetAssigneeId: targetAssigneeId,
                    selectedList: [item],
                    timeConstrained: item.timeConstrained,
                    resource: targetAssigneeResource,
                    startDateUtc: item.startDateUtc,
                    endDateUtc: item.endDateUtc,
                    identifier,
                    resourceTypeRoleId: resourceTypeRoleId_ConfigName
                        ? configSettings?.value
                        : poilicyAssignee?.id,
                })
                itemsToAdd.push(itemToAdd[0])
            })

            if (!itemsToAdd) return undefined
            return itemsToAdd
        }
    }

    const handleAddToCart = () => {
        if (!onSubmit) {
            const itemToAdd = preAdd()
            if (itemToAdd === undefined) return
            addItemsToCart(itemToAdd).then(() => postAdd())
        } else {
            if (!allowRemovingAssignments) {
                onSubmit(peopleAddedList)
            } else {
                if (addSelectedOnly) {
                    const _alreadyAssigned = list.filter((l) => l.isAssigned)
                    onSubmit(peopleAddedList, _alreadyAssigned)
                } else {
                    const filtered = alreadyAssignedValues.filter(
                        (el) =>
                            !peopleAddedList.some(
                                (i) => i[identifier] === el[identifier],
                            ),
                    )
                    onSubmit([
                        ...peopleAddedList.filter((i) => !i.isAssigned),
                        ...filtered,
                    ])
                    setIsSubmitting(true)
                }
                if (closeOnSubmit) {
                    handleModalClose()
                }
            }
        }
    }

    const checkIfAlreadyAddedToCart = (item) => {
        const cartItems = cart?.cartItems || []
        if (cartItems?.length) {
            return cartItems.find(
                (x) => x.requestableResourceId === item[identifier],
            )
                ? true
                : false
        }
        return false
    }
    const getTooltipTitle = () => {
        if (showPermissionLevel && !poilicyAssignee) {
            return t('PermissionLevelRequired')
        }
        if (!allowRemovingAssignments && peopleAddedList.length <= 0) {
            return t('NoChangeInAssignment')
        } else {
            const anyChangeinAssignment = peopleAddedList.every((first) =>
                alreadyAssignedValues.some(
                    (second) => first[identifier] === second[identifier],
                ),
            )
            if (
                peopleAddedList.length === alreadyAssignedValues.length &&
                anyChangeinAssignment
            ) {
                return t('NoChangeInAssignment')
            }
        }
        return ''
    }
    const hasTooltipValue = getTooltipTitle() !== '' || configSettingsLoading

    const handleDoubleClickForAdding = (e, item) => {
        if (
            checkIfAlreadyAddedToSelectedList(item) ||
            checkIfAlreadyAddedToCart(item)
        ) {
            return
        }
        if (timeConstrained) {
            item.timeConstrained = timeConstrained
            item.startDateUtc = timeConstrained ? selectedStartDate : null
            item.endDateUtc = timeConstrained ? selectedEndDate : null
        }
        setPeopleAddedList([...peopleAddedList, item])
        setSelectedAddedAssignments((s) =>
            s.filter((el) => el[identifier] !== item[identifier]),
        )
    }
    const handleDoubleClickForRemoving = (e, item) => {
        if (item?.isInherited && item?.isAssigned) {
            return
        }
        const listAfterSelectedRemoved = peopleAddedList.filter((el) => {
            return item[identifier] !== el[identifier]
        })
        setPeopleAddedList(listAfterSelectedRemoved)
        setSelectedAssignments((s) =>
            s.filter((el) => el[identifier] !== item[identifier]),
        )
    }

    useEffect(() => {
        if (numberOfPages_RightSide < page_RightSide) {
            setPage_RightSide(
                numberOfPages_RightSide > 0 ? numberOfPages_RightSide : 1,
            )
        }
    }, [peopleAddedList])

    return (
        <>
            {(labelKey || startIcon) && (
                <Button
                    onClick={() => setOpenAssigneeModal(true)}
                    style={{
                        borderRadius: '4px',
                        backgroundColor: theme?.palette?.primary?.main,
                        color: '#ffffff',
                        fontSize: '14px',
                        height: '100%',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                >
                    <Box style={{ display: 'flex' }}>
                        {startIcon}
                        {t(labelKey)}
                    </Box>
                </Button>
            )}
            <CustomDialog
                open={openAssigneeModal}
                onClose={() => handleModalClose()}
                title={t('')}
            >
                <AssignmentsModal>
                    <Box className={classes.headerBox}>
                        <CloseIconeBox onClick={() => handleModalClose()}>
                            <Icon
                                name="Close"
                                width="12px"
                                height="13px"
                                color="#959598"
                            />
                        </CloseIconeBox>

                        <Typography variant="h6">
                            {headerText ? (
                                <span>
                                    <strong>{headerText.dynamicText}</strong>
                                    {` - ${t(headerText.text)}`}
                                </span>
                            ) : (
                                t(labelKey)
                            )}
                        </Typography>
                    </Box>

                    <Box className={classes.bodyBox} marginTop="10px">
                        <PageContextProvider key={'PermissionLevelSelection'}>
                            {showPermissionLevel && openAssigneeModal && (
                                <>
                                    <PermissionLevels
                                        value={poilicyAssignee}
                                        permissionLevelProps={
                                            permissionLevelProps
                                        }
                                        onChange={(e) =>
                                            handlePolicyAssigneChange(e)
                                        }
                                        targetAssigneeId={targetAssigneeId}
                                    />
                                    <Divider />
                                </>
                            )}
                        </PageContextProvider>
                        {showTimeConstrainedFilter && devFeaturesEnabled && (
                            <>
                                <Button
                                    onClick={
                                        () =>
                                            console.log('Filter permanent list') //TODO : Add when filtering functionality is available
                                    }
                                    disabled={policy?.isTimeConstrained}
                                    className={classNames(
                                        classes.timeConstraintSwitchButton,
                                        {
                                            [classes.activeTimeConstraintSwitchButton]:
                                                !timeConstrained,
                                            [classes.disabledTimeConstraintSwitchButton]:
                                                policy?.isTimeConstrained,
                                        },
                                    )}
                                    style={{
                                        marginRight: '16px',
                                    }}
                                    startIcon={
                                        <Icon
                                            name="Clock"
                                            color={
                                                timeConstrained
                                                    ? '#8b909a'
                                                    : '#265fb4'
                                            }
                                        />
                                    }
                                >
                                    {t('Permanent')}
                                </Button>
                                <Button
                                    onClick={
                                        () =>
                                            console.log(
                                                'Filter time constrained list',
                                            ) //TODO : Add when filtering functionality is available
                                    }
                                    className={classNames(
                                        classes.timeConstraintSwitchButton,
                                        {
                                            [classes.activeTimeConstraintSwitchButton]:
                                                timeConstrained,
                                        },
                                    )}
                                    startIcon={
                                        <Icon
                                            name="Clock"
                                            color={
                                                !timeConstrained
                                                    ? '#8b909a'
                                                    : '#265fb4'
                                            }
                                        />
                                    }
                                >
                                    {t('TimeConstraint')}
                                </Button>
                                <Divider />
                            </>
                        )}

                        {(!showPermissionLevel || poilicyAssignee) && (
                            <>
                                <Box classes={{ root: classes.SearchPeople }}>
                                    <Box className={classes.searchBar}>
                                        <Search
                                            handleSearch={handleSearchChange}
                                            placeholder={t(searchPlaceHolder)}
                                            value={searchKey}
                                            handleClear={handleClear}
                                            outlined
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex">
                                    <Box
                                        className={
                                            classes.SelectAssignmentTitle
                                        }
                                    >
                                        <Typography
                                            style={{
                                                fontSize: '10px',
                                                color: '#b4b4b4',
                                                marginTop: '10px',
                                            }}
                                        >
                                            {t(listLabels.unselectedListLabel)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        className={
                                            classes.SelectAssignmentTitleblank
                                        }
                                    ></Box>

                                    <Box
                                        className={
                                            classes.SelectAssignmentTitle
                                        }
                                    >
                                        <Typography
                                            style={{
                                                fontSize: '10px',
                                                color: '#b4b4b4',
                                                marginTop: '10px',
                                            }}
                                        >
                                            {t(listLabels.selectedListLabel)}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box display="flex" flex={'1'}>
                                    <Box
                                        classes={{
                                            root: classes.SelectAssignmentSectionContainer,
                                        }}
                                    >
                                        <Box marginTop="10px">
                                            <SelectAssignmentSection>
                                                {loading && (
                                                    <Box
                                                        className={
                                                            classes.AssignmentsModalloader
                                                        }
                                                    >
                                                        <Loader />
                                                    </Box>
                                                )}
                                                {paginatedData_LeftSide?.length
                                                    ? paginatedData_LeftSide.map(
                                                          (item, index) => (
                                                              <MemberIAMShopAssigment
                                                                  isAlreadyAddedToCart={checkIfAlreadyAddedToCart(
                                                                      item,
                                                                  )}
                                                                  handleSelectPeople={
                                                                      handleSelectPeople
                                                                  }
                                                                  isSelectedAttribute={checkIfSelected(
                                                                      item,
                                                                  )}
                                                                  isUnAssigned={
                                                                      item?.isAssigned
                                                                  }
                                                                  item={item}
                                                                  index={index}
                                                                  handleDoubleClick={
                                                                      handleDoubleClickForAdding
                                                                  }
                                                                  isLeftSide={
                                                                      true
                                                                  }
                                                                  secondaryAttributes={
                                                                      secondaryAttributes
                                                                  }
                                                              />
                                                          ),
                                                      )
                                                    : null}
                                            </SelectAssignmentSection>
                                        </Box>
                                    </Box>

                                    <ButtonSection>
                                        <div
                                            className={
                                                classes.ButtonRemoveSection
                                            }
                                        >
                                            {showAccessRequestPolicy &&
                                                policy &&
                                                config.HIDE_DURATION_SELECTION_IN_ASSIGNMENTS_POPUP !==
                                                    'true' && (
                                                    <PolicyDetails
                                                        policy={policy}
                                                        orientation="vertical"
                                                        showValuesIfUnrestricted={
                                                            false
                                                        }
                                                    />
                                                )}

                                            {/* TODO: Remove this config check once RESADMIN-3502 is completed */}
                                            {config.HIDE_DURATION_SELECTION_IN_ASSIGNMENTS_POPUP !==
                                                'true' && (
                                                <>
                                                    {!hideDurationSelection && (
                                                        <Box>
                                                            <ToggleSwitch
                                                                value={
                                                                    timeConstrained
                                                                }
                                                                label={t(
                                                                    'SetDuration',
                                                                )}
                                                                labelColor="#5d6870"
                                                                disabled={
                                                                    policy?.isTimeConstrained
                                                                }
                                                                onChange={() =>
                                                                    setTimeConstrained(
                                                                        (
                                                                            prev,
                                                                        ) =>
                                                                            !prev,
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    )}

                                                    {timeConstrained && (
                                                        <>
                                                            {showStartDate && (
                                                                <Box
                                                                    padding={
                                                                        '0px 4px'
                                                                    }
                                                                >
                                                                    <StartDateTimePicker
                                                                        selectedStartDate={
                                                                            selectedStartDate
                                                                        }
                                                                        setSelectedStartDate={
                                                                            handleStartDate
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                            <Box
                                                                padding={
                                                                    '0px 4px'
                                                                }
                                                            >
                                                                <EndDateTimePicker
                                                                    selectedEndDate={
                                                                        selectedEndDate
                                                                    }
                                                                    setSelectedEndDate={
                                                                        handleEndDate
                                                                    }
                                                                    minEndDate={moment(
                                                                        selectedStartDate,
                                                                    )}
                                                                    maxEndDate={
                                                                        policy?.isTimeConstrained
                                                                            ? moment(
                                                                                  selectedStartDate,
                                                                              ).add(
                                                                                  policy?.maximumValueInMinutes,
                                                                                  'minute',
                                                                              )
                                                                            : null
                                                                    }
                                                                    hasInvalidDates={
                                                                        hasInvalidDates
                                                                    }
                                                                    validationMessage={
                                                                        invalidDatesMessage
                                                                    }
                                                                />
                                                            </Box>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            <Button
                                                onClick={() =>
                                                    handleAddSelectedPeople()
                                                }
                                                disabled={
                                                    !selectedAssignments?.length ||
                                                    hasInvalidDates
                                                }
                                                className={classNames(
                                                    classes.inactiveButtonForAdd,
                                                    {
                                                        [classes.activeButtonForAdd]:
                                                            selectedAssignments?.length &&
                                                            !hasInvalidDates,
                                                    },
                                                )}
                                                style={{
                                                    marginTop:
                                                        showAccessRequestPolicy
                                                            ? '15px !important'
                                                            : '60px',
                                                }}
                                            >
                                                <label>{t('Add')}</label>

                                                <ArrowSmallIcon
                                                    color="#8b909a"
                                                    fill="#8b909a"
                                                    direction="right"
                                                />
                                            </Button>

                                            <Button
                                                onClick={() =>
                                                    handleRemove('selected')
                                                }
                                                disabled={
                                                    !selectedAddedAssignments?.length
                                                }
                                                className={classNames(
                                                    classes.inactiveButtonForRemove,
                                                    {
                                                        [classes.activeButtonForRemove]:
                                                            selectedAddedAssignments?.length,
                                                    },
                                                )}
                                            >
                                                <ArrowSmallIcon
                                                    color="#8b909a"
                                                    fill="#8b909a"
                                                    direction="left"
                                                />
                                                <label>{t('Remove')}</label>
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    handleRemove('all')
                                                }
                                                disabled={
                                                    !peopleAddedList?.length
                                                }
                                                className={classNames(
                                                    classes.inactiveButtonForRemoveAll,
                                                    {
                                                        [classes.activeButtonForRemoveAll]:
                                                            peopleAddedList?.length,
                                                    },
                                                )}
                                            >
                                                <ArrowSmallIcon
                                                    color="#8b909a"
                                                    fill="#8b909a"
                                                    direction="left"
                                                />
                                                <label>{t('RemoveAll')}</label>
                                            </Button>
                                        </div>
                                    </ButtonSection>

                                    <Box
                                        classes={{
                                            root: classes.SelectAssignmentSectionContainer,
                                        }}
                                    >
                                        <Box marginTop="10px">
                                            <SelectAssignmentSection>
                                                {paginatedData_RightSide?.length
                                                    ? paginatedData_RightSide.map(
                                                          (item, index) => (
                                                              <MemberIAMShopAssigment
                                                                  isAlreadyAddedToCart={checkIfAlreadyAddedToCart(
                                                                      item,
                                                                  )}
                                                                  handleSelectPeople={
                                                                      handleAddedPeopleChange
                                                                  }
                                                                  isSelectedAttribute={checkIfAddedSelected(
                                                                      item,
                                                                  )}
                                                                  isUnAssigned={
                                                                      item?.isAssigned
                                                                  }
                                                                  item={item}
                                                                  index={index}
                                                                  isRightSide={
                                                                      true
                                                                  }
                                                                  handleDoubleClick={
                                                                      handleDoubleClickForRemoving
                                                                  }
                                                                  secondaryAttributes={
                                                                      item?.timeConstrained
                                                                          ? [
                                                                                'endDateUtc',
                                                                                ...secondaryAttributes,
                                                                            ]
                                                                          : secondaryAttributes
                                                                  }
                                                                  isInherited={
                                                                      item?.isInherited &&
                                                                      item?.isAssigned
                                                                  }
                                                              />
                                                          ),
                                                      )
                                                    : null}
                                            </SelectAssignmentSection>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display="flex">
                                    <PaginationMain>
                                        {allowRemovingAssignments &&
                                        numberOfPages_LeftSide > 1 ? (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                width="100%"
                                                justifyContent="center"
                                                padding="5px 11px"
                                            >
                                                <Pagination
                                                    count={
                                                        numberOfPages_LeftSide
                                                    }
                                                    size="large"
                                                    siblingCount={1}
                                                    page={page_LeftSide}
                                                    onChange={(_, value) => {
                                                        setPage_LeftSide(value)
                                                    }}
                                                    variant={'rounded'}
                                                />
                                            </Box>
                                        ) : (
                                            pagination
                                        )}
                                    </PaginationMain>
                                    <Box
                                        style={{
                                            maxWidth: '225px',
                                            width: '100%',
                                        }}
                                    ></Box>
                                    <PaginationMain>
                                        {numberOfPages_RightSide > 1 && (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                width="100%"
                                                justifyContent="center"
                                            >
                                                <Pagination
                                                    count={
                                                        numberOfPages_RightSide
                                                    }
                                                    size="large"
                                                    siblingCount={1}
                                                    page={page_RightSide}
                                                    onChange={(_, value) => {
                                                        setPage_RightSide(value)
                                                    }}
                                                    variant={'rounded'}
                                                />
                                            </Box>
                                        )}
                                    </PaginationMain>
                                </Box>
                                <Divider />
                            </>
                        )}

                        <ModalFooter
                            classes={{ root: classes.TimeSectionFooter }}
                        >
                            <Tooltip title={getTooltipTitle()}>
                                <div>
                                    <Button
                                        loading={isLoading || submitLoader}
                                        onClick={() => handleAddToCart()}
                                        backgroundColor={
                                            theme?.palette?.primary?.main
                                        }
                                        disabled={hasTooltipValue}
                                        disabledStyles={{
                                            backgroundColor:
                                                '#aab0b4 !important',
                                            borderColor: '#aab0b4 !important',
                                            color: '#ffffff !important',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {submitButtonLabelKey
                                            ? t(submitButtonLabelKey, {
                                                  count: peopleAddedList.length,
                                              })
                                            : t(labelKey)}
                                    </Button>
                                </div>
                            </Tooltip>
                            <Button onClick={() => handleModalClose()}>
                                {t('Cancel')}
                            </Button>
                        </ModalFooter>
                    </Box>
                </AssignmentsModal>
            </CustomDialog>
        </>
    )
}

export default AddAssignment
