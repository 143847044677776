import { Fragment, useEffect, useState, useCallback, useRef } from 'react'
import { Collapse, Box, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import ApplicationCard from './ApplicationCard'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
    useGetControlsAccess,
} from 'packages/core'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import ApplicationDetails from './ApplicationDetails'
import {
    ApplicationsTable,
    ApplicationsTableHead,
    ApplicationRow,
} from './ApplicationsTableComponents'
import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'

import { useApplicationSettings } from 'hooks'
import { ApplicationBanner } from 'components/ApplicationBanner'
import ReactHtmlParser from 'react-html-parser'
import DomPurify from 'dompurify'
import { usePageContext } from 'pageContext'
import { useOwnedApplications } from 'hooks'
import { SubPathLevel } from 'appConfiguration'
import useSubcomponents from 'useSubcomponents'
import config from 'config'
import ListingTabs from 'components/ListingTabs'

import ClaimsMappingPolicyDetails from './ClaimsMappingPolicy/PolicyDetails'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'

const ApplicationsLayout = ({ _filters }) => {
    const LISTING_TABS = {
        name: 'ListingTabs',
        requireAccess: 'ResourceAdmin-Applications-Navigation-Tabs-Control',
        tabs: [
            {
                label: 'ApplicationsList',
                name: 'ApplicationsList',
                display: true,
                showCount: true,
                icon: 'Applications',
                default: true,
                path: 'applications',
            },
            {
                label: 'ClaimsMappingPolicies',
                name: 'ClaimsPolicies',
                display: true,
                showCount: true,
                icon: 'Claims',
                path: 'claimsMappingPolicies',
                url: 'api/claimsmappingpolicy/all',
            },
        ],
    }
    const controls = useGetControlsAccess()
    const hasAccessToViewListingTabs =
        controls.findIndex((c) => c.name === LISTING_TABS.requireAccess) >= 0
    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const query = useQuery()
    const selectedTab = query.get('tab')
    const [state, dispatch] = usePageContext()
    const [listingTab, setListingTab] = useState(
        hasAccessToViewListingTabs && selectedTab
            ? selectedTab
            : 'applications',
    )
    const selectedListingTab = LISTING_TABS.tabs.filter(
        (f) => f.path.toLowerCase() === listingTab.toLowerCase(),
    )[0]
    const filters = _filters
        .filter((f) => f.tabs[selectedListingTab.path])
        .map((f) => f)
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        refetch,
        rest,
    } = useOwnedApplications(selectedListingTab)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [filterWidth, setFilterWidth] = useState(0)

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])

    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const { getAccessBasedAttributes } = useSubcomponents()

    const applicationAttributes = attributes.filter(
        (a) => !a.isClaimPolicyAttributeOnly,
    )
    const claimMappingPolicyAttributes = attributes.filter(
        (a) => a.isClaimPolicyAttributeOnly || a.isClaimPolicyAttribute,
    )
    const listingAttributes =
        listingTab === 'applications'
            ? applicationAttributes
            : claimMappingPolicyAttributes

    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('NoDataMessage'),
        pagination,
        attributes: getAccessBasedAttributes(listingAttributes),
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: ApplicationCard,
        },
        tableViewConfig: {
            TableComponent: ApplicationsTable,
            TableHeadComponent: ApplicationsTableHead,
            TableRowComponent: ApplicationRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    const hasAccessToCreateAzureApplicationWorkflow =
        controls.findIndex(
            (c) =>
                c.name ===
                'ResourceAdmin-Applications-CreateAzureApplicationWorkflow-Control',
        ) >= 0
    const hasAccessToParsedHtmlMoreInfo =
        controls.findIndex(
            (c) => c.name === 'ResourceAdmin-ParsedHtmlMoreInformation-Control',
        ) >= 0
    const applicationId = query.get('applicationId')
    const claimsMappingPolicyId = query.get('claimsMappingPolicyId')
    const showDetailsPage = applicationId || claimsMappingPolicyId

    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig
    const onboardAzureApplicationWF =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) => w.name === 'OnboardAzureApplication',
              )
            : []
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )
    let parsedHtmlMoreInfo
    try {
        parsedHtmlMoreInfo = ReactHtmlParser(
            DomPurify.sanitize(
                t(
                    listingTab === 'applications'
                        ? 'MoreInfo_Html'
                        : 'ClaimsMappingPolicyMoreInfo_Html',
                ),
                { ADD_ATTR: ['target'] },
            ),
        )
    } catch (error) {}

    let detailContent
    if (applicationId) {
        detailContent = (
            <>
                {isSmallScreen ? (
                    <ApplicationDetails
                        id={applicationId}
                        onClose={() => {
                            history.push(history.location.pathname)
                            refetch()
                        }}
                    />
                ) : (
                    <DesktopContainer>
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <ApplicationBanner applicationId={applicationId} />
                        </Box>
                    </DesktopContainer>
                )}
            </>
        )
    }

    if (claimsMappingPolicyId) {
        detailContent = (
            <>
                {isSmallScreen ? (
                    <ClaimsMappingPolicyDetails id={claimsMappingPolicyId} />
                ) : (
                    <DesktopContainer>
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <ClaimsMappingPolicyDetails
                                id={claimsMappingPolicyId}
                            />
                        </Box>
                    </DesktopContainer>
                )}
            </>
        )
    }

    let listingContent
    listingContent = (
        <>
            {isSmallScreen ? (
                <SmallScreenContainer>
                    <Box display={showFilters ? '' : 'none'}>
                        {mobileFiltersScreen}
                    </Box>

                    <Box display={!showFilters ? '' : 'none'}>
                        <Box display={''}>
                            <StickyContainer>
                                <Box width="100%">
                                    {mobileStickyContentListing}
                                </Box>
                            </StickyContainer>
                            {viewToRender}
                        </Box>
                    </Box>
                </SmallScreenContainer>
            ) : (
                <DesktopContainer>
                    <Fragment>
                        {((filters && filters.length > 0) ||
                            hasAccessToViewListingTabs ||
                            (hasAccessToCreateAzureApplicationWorkflow &&
                                onboardAzureApplicationWF.length > 0) ||
                            (hasAccessToParsedHtmlMoreInfo &&
                                parsedHtmlMoreInfo)) && (
                            <FiltersColumn ref={filterContainer}>
                                <FilterContainer>
                                    <Box
                                        padding="16px 0px 0px 0px"
                                        display="flex"
                                    >
                                        <Box width="100%">
                                            <Card.CardHeader
                                                collapsible
                                                fontColor="black"
                                                cardTitle={t('Show_My_Filters')}
                                                handleExpandClick={() =>
                                                    setOpenFilters(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                expanded={openFilters}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                                style={styles.filterCardHeader}
                                            />
                                        </Box>
                                    </Box>
                                </FilterContainer>

                                <Collapse
                                    in={openFilters}
                                    timeout={300}
                                    unmountOnExit
                                >
                                    {hasAccessToCreateAzureApplicationWorkflow &&
                                        onboardAzureApplicationWF.length >
                                            0 && (
                                            <Box
                                                style={{ margin: '0 0 15px 0' }}
                                            >
                                                <EidWorkflowButton
                                                    title={t(
                                                        onboardAzureApplicationWF[0]
                                                            .displayName_LocaleKey,
                                                    )}
                                                    name={
                                                        onboardAzureApplicationWF[0]
                                                            .requestWorkflowName
                                                    }
                                                    path={`/applications/workflows`}
                                                />
                                            </Box>
                                        )}

                                    {hasAccessToViewListingTabs && (
                                        <Box
                                            style={{
                                                marginBottom: '28px',
                                                width: '100%',
                                            }}
                                        >
                                            <Paper>
                                                <ListingTabs
                                                    active={listingTab}
                                                    tabs={LISTING_TABS.tabs}
                                                    activeTab={
                                                        LISTING_TABS.tabs.filter(
                                                            (t) =>
                                                                t.path ===
                                                                listingTab,
                                                        )[0]
                                                    }
                                                    onChange={(tab) => {
                                                        dispatch({
                                                            type: 'CLEAR_FILTERS_SORTING',
                                                            payload: null,
                                                        })
                                                        setListingTab(tab.path)
                                                        history.push(
                                                            !tab.default
                                                                ? `${history.location.pathname}?tab=${tab.path}`
                                                                : `${history.location.pathname}`,
                                                        )
                                                    }}
                                                    count={totalCount}
                                                />
                                            </Paper>
                                        </Box>
                                    )}
                                    {hasAccessToParsedHtmlMoreInfo &&
                                        parsedHtmlMoreInfo && (
                                            <Box
                                                style={{
                                                    margin: '0 0 15px 0px',
                                                }}
                                            >
                                                <Card
                                                    cardTitle={t(
                                                        'More_Information',
                                                    )}
                                                    icon={
                                                        <Icon name="MoreInfo" />
                                                    }
                                                    collapsible
                                                    expanded
                                                >
                                                    <Box
                                                        padding="0px 10px"
                                                        maxWidth="350px"
                                                        overflow="scroll"
                                                    >
                                                        {parsedHtmlMoreInfo}
                                                    </Box>
                                                </Card>
                                            </Box>
                                        )}
                                    <>
                                        {filters.map((f) => {
                                            const FilterToRender =
                                                Filters.filtersMap[f.name]
                                            return (
                                                <FilterContainer
                                                    data-protectedsubcomponent={
                                                        f.requireAccess
                                                    }
                                                    key={f.name}
                                                >
                                                    <FilterToRender />
                                                </FilterContainer>
                                            )
                                        })}
                                    </>
                                    <ListingPageUIActionByNounAndVerb
                                        noun="ResourceAdmin"
                                        verb="Application"
                                    />
                                </Collapse>
                            </FiltersColumn>
                        )}

                        <ListingGrid>
                            <Box padding="16px 0px 0px 0px" display="flex">
                                <Box width="100%">
                                    <Filters.TextSearch />
                                </Box>
                                {/* More Filters Here */}
                            </Box>
                            <GridContainer
                                filterWidth={filterWidth}
                                openFilters={openFilters}
                            >
                                {viewToRender}
                            </GridContainer>
                        </ListingGrid>
                    </Fragment>
                </DesktopContainer>
            )}
        </>
    )

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showNavTabs={!showDetailsPage}
            showBackButton={showDetailsPage}
        >
            <Fragment>
                {showDetailsPage ? detailContent : listingContent}
            </Fragment>
        </AppLayout>
    )
}

export default ApplicationsLayout
