import { useQuery, useMutation, queryCache } from 'react-query'
import { useAxios } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useNotification } from 'packages/eid-ui'
import { usePageContext } from 'pageContext'
import useApiWithInfiniteScroll from './useApiWithInfiniteScroll'
import { useQuery as useCoreQuery } from 'packages/core'

export const APPLICATIONS_KEY = 'APPLICATIONS'

export const useApplication = (id: any) => {
    const callApi = useAxios()
    const query = useCoreQuery()
    const returnedFromWorkflow = query.get('returnedFromWorkflow') === 'true'
    return useQuery(
        [APPLICATIONS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/applications/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
            retry: (failureCount, error: any) => {
                return returnedFromWorkflow && error.response.status === 404
                    ? false
                    : true
            },
        },
    )
}

export const useOwnedApplications = (tab: any) => {
    const [state]: any = usePageContext()

    const {
        searchTerm,
        startDate,
        endDate,
        sortOrder,
        sortBy,
        status,
        itemType,
        accountStore,
        accountStoreUsageType,
        ownedBy,
        shouldLoadTags,
        advancedSearchTags,
        showOnlyAzureApplications,
        includeBasicClaimSet,
        enableOwnedByApiCall,
    } = state
    const ownerPersonId = ownedBy?.id
    const description = state['advancedSearch.forms.description']
    const friendlyName = state['advancedSearch.forms.friendlyName']
    const technicalName = state['advancedSearch.forms.technicalName']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (startDate) {
        queryData.startDateUtc = startDate
    }
    if (endDate) {
        queryData.endDateUtc = endDate
    }
    if (accountStoreUsageType) {
        queryData.accountStoreUsageTypeId = accountStoreUsageType.id
    }
    if (accountStore) {
        queryData.accountStoreId = accountStore.id
    }

    if (shouldLoadTags) {
        queryData.includeTags = true
    }

    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }
    if (includeBasicClaimSet) {
        queryData.includeBasicClaimSet = includeBasicClaimSet === 'true'
    }

    const advancedSearch = []
    if (description) {
        advancedSearch.push({
            name: 'Description',
            type: 'string',
            value: description,
        })
    }
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }
    if (technicalName) {
        advancedSearch.push({
            name: 'Name',
            type: 'string',
            value: technicalName,
        })
    }

    queryData.advancedSearch = advancedSearch

    const endpoint =
        tab.path === 'applications'
            ? ownerPersonId
                ? `/api/applications/owned/${ownerPersonId}`
                : `api/applications/all`
            : tab.url

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATIONS',
            tab.path,
            searchTerm,
            sortBy,
            sortOrder,
            startDate,
            endDate,
            status,
            itemType,
            description,
            friendlyName,
            technicalName,
            ownerPersonId,
            accountStore,
            accountStoreUsageType,
            advancedSearchTags,
            shouldLoadTags,
            showOnlyAzureApplications,
            includeBasicClaimSet,
            enableOwnedByApiCall,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    showOnlyAzureApplications,
                },
            })
        },
        {
            enabled: enableOwnedByApiCall,
        },
    )
}

export const useApplicationDirectAssignedLocations = (id: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'DIRECT_ASSIGNED_LOCATIONS', id],
        () =>
            callApi({
                method: 'GET',
                url: `api/applications/directAssignedLocations/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useAzureApplicationOwnersAndDeputies = (id: string) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'OWNERS_AND_DEPUTIES', id],
        () =>
            callApi({
                method: 'GET',
                url: `api/applications/OwnersDeputies/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationWorkflows = (
    noun: string,
    verb: string,
    id?: string,
) => {
    const [state]: any = usePageContext()
    const { searchTerm } = state

    let url = `api/UIActions/byNoun?noun=${noun}&verb=${verb}`
    if (searchTerm) {
        url = url + `&searchTerm=${searchTerm}`
    }

    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'WORKFLOWS', id, noun, verb, searchTerm],
        () =>
            callApi({
                method: 'GET',
                url: url,
            }).then((data) => data.data),
    )
}

export const useApplicationCertificates = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/certificates/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_CERTIFICATES',
            sortBy,
            sortOrder,
            searchTerm,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationSecrets = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/secrets/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_SECRETS',
            sortBy,
            sortOrder,
            searchTerm,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationSecret = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'SECRET_DETAILS', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/secrets/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationCertificate = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'CERTIFICATE_DETAILS', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/certificates/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationCredentialsHiddenProps = (
    id: any,
    masterPassword: any,
    isEnabled: true,
) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'SECRET_PROPS', id, isEnabled],
        () =>
            callApi({
                method: 'POST',
                url: `/api/CredentialManagement/hiddenProperties/${id}`,
                data: `"${masterPassword}"`,
            }).then((data) => data.data),
        {
            enabled: !!masterPassword && isEnabled,
        },
    )
}
export const clearModalSearch = () => {
    queryCache.removeQueries((c) => c.queryKey.includes(`MODAL_SEARCH_KEY`))
}
export const refreshListings = () => {
    queryCache.invalidateQueries(
        (c) =>
            c.queryKey.includes('LIST_MY_APPLICATION_SECRETS') ||
            c.queryKey.includes('LIST_MY_APPLICATION_CERTIFICATES'),
    )
}

export const refreshPermissionsListing = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('LIST_MY_APPLICATION_PERMISSIONS'),
    )
}

export const refreshScopesListing = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('LIST_MY_APPLICATION_SCOPES'),
    )
}

export const refreshAppRolesListing = () => {
    queryCache.invalidateQueries((c) =>
        c.queryKey.includes('LIST_MY_APPLICATION_ROLES'),
    )
}

export const refreshRoleDefinitionsListing = () => {
    queryCache.invalidateQueries(
        (c) =>
            c.queryKey.includes('LIST_MY_APPLICATION_ROLE_DEFINITIONS') ||
            c.queryKey.includes(
                'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_ROLES_DEFINITIONS',
            ),
    )
}

export const refreshCredentialDetails = (credentialId: any) => {
    queryCache.invalidateQueries(
        (c) =>
            (c.queryKey.includes('SECRET_DETAILS') ||
                c.queryKey.includes('CERTIFICATE_DETAILS')) &&
            c.queryKey.includes(credentialId),
    )
}

export const useCheckInCredential = (id: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (checkOutId) =>
            callApi({
                method: 'POST',
                url: `/api/CredentialManagement/checkin/${checkOutId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CredentialCheckedIn'))
                refreshListings()
                refreshCredentialDetails(id)
            },
            onError: () => {
                showWarningMessage(t('CredentialNotCheckedIn'))
            },
        },
    )
}

export const useCheckOutCredential = (id: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: '/api/CredentialManagement/checkout',
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CredentialCheckedOut'))
                refreshListings()
                refreshCredentialDetails(id)
            },
            onError: () => {
                showWarningMessage(t('CredentialNotCheckedOut'))
            },
        },
    )
}

export const useDeleteCredential = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (credentialId) =>
            callApi({
                method: 'DELETE',
                url: `/api/CredentialManagement/${credentialId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('CredentialDeleted'))
                refreshListings()
            },
            onError: () => {
                showWarningMessage(t('CredentialNotDeleted'))
            },
        },
    )
}

export const useCheckOutCredentialsRecords = (
    CredentialId: any,
    queryData: any,
) => {
    const callApi = useAxios()
    const endpoint = `api/CredentialManagement/checkOutCredentialsRecordsbri`
    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'CHECKOUT_CREDENTIAL_RECORDS',
            queryData,
            CredentialId,
        ],

        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take: 15,
                    CredentialId,
                },
            })
        },
    )
}

export const useApplicationScopes = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/LocalRights/scopes`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_SCOPES',
            sortBy,
            sortOrder,
            searchTerm,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationScope = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'SCOPE', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/LocalRights/scopes/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationRoles = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/LocalRights/appRoles`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_ROLES',
            sortBy,
            sortOrder,
            searchTerm,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationRole = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'APPROLE', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/LocalRights/appRoles/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationPermissions = (azureApplicationId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/permissions/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_PERMISSIONS',
            sortBy,
            sortOrder,
            searchTerm,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    azureApplicationId,
                },
            })
        },
    )
}

export const useApplicationPermission = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'PERMISSION', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/permissions/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useDeletePermission = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (permissionId) =>
            callApi({
                method: 'DELETE',
                url: `/api/permissions/${permissionId}`,
            }),
        {
            onSuccess: (res) => {
                let message = t('PermissionDeleted')
                if (res?.data?.wentForApproval) {
                    message = t('WentForApproval')
                }

                showSuccessMessage(message)
                refreshPermissionsListing()
            },
            onError: () => {
                showWarningMessage(t('PermissionNotDeleted'))
            },
        },
    )
}

export const useDeleteScope = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (globalRightId) =>
            callApi({
                method: 'DELETE',
                url: `/api/LocalRights/scopes/${globalRightId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ScopeDeleted'))
                refreshScopesListing()
            },
            onError: () => {
                showWarningMessage(t('ScopeNotDeleted'))
            },
        },
    )
}

export const useDeleteAzureApplicationRole = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data: any) =>
            callApi({
                method: 'DELETE',
                url: `/api/LocalRights/appRoles/${data.id}/${data.resourceSystemModuleId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('AppRoleDeleted'))
                refreshAppRolesListing()
            },
            onError: () => {
                showWarningMessage(t('AppRoleNotDeleted'))
            },
        },
    )
}

export const useRequestAssigneeTypesByResourceTypeId = (
    resourceTypeId: any,
    resourceId: any,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            APPLICATIONS_KEY,
            'REQUEST_ASSIGNEE_TYPES',
            resourceTypeId,
            resourceId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ResourceAssignments/requestAssigneeTypes/${resourceTypeId}/${resourceId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(resourceTypeId) && Boolean(resourceId),
        },
    )
}

export const useIAmShopAssignees = (resourceId: any, queryFilters: any) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (queryFilters.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }
    if (queryFilters.sorting) {
        queryData.sortBy = queryFilters.sorting.sortBy
        queryData.desc =
            queryFilters.sorting.sortOrder === 'desc' ? true : false
    }
    if (queryFilters.take) {
        queryData.take = queryFilters.take
    }

    return useApiWithInfiniteScroll(
        [`${APPLICATIONS_KEY}_IAMSHOP_ASSIGNEES`, resourceId, queryData],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/ResourceAssignments/accessLevels`,
                method: 'POST',
                data: {
                    skip,
                    take,
                    resourceId,
                    ...queryData,
                },
            })
        },
    )
}

export const useIAmShopAssignments = (resourceId: any) => {
    const callApi = useAxios()
    const queryData: any = {}
    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    return useApiWithInfiniteScroll(
        [
            `${APPLICATIONS_KEY}_IAMSHOPASSIGNMENTS`,
            resourceId,
            sortOrder,
            sortBy,
            searchTerm,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/ResourceAssignments/accessLevelsMembers`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    resourceId,
                },
            })
        },
    )
}

export const refreshIAMShopAssigneesListing = (applicationId: any) => {
    queryCache.invalidateQueries(
        (c) =>
            c.queryKey.includes(`${APPLICATIONS_KEY}_IAMSHOP_ASSIGNEES`) &&
            c.queryKey.includes(applicationId),
    )
}

export const useDeleteIAMShopAssignees = (applicationId: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (ids) =>
            callApi({
                method: 'POST',
                url: `/api/ResourceAssignments/deleteAccessLevel`,
                data: {
                    resourceAccessRequestAssigneeIds: ids,
                },
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('AssigneesDeleted'))
                refreshIAMShopAssigneesListing(applicationId)
            },
            onError: () => {
                showWarningMessage(t('AssigneesNotDeleted'))
            },
        },
    )
}

export const useClaims = (policyId: any, queryFilters: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    return useApiWithInfiniteScroll(
        [`${APPLICATIONS_KEY}_CLAIMS`, policyId, queryData],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/claims/${policyId}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: Boolean(policyId),
        },
    )
}

export const useClaimsMappingPolicy = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'CLAIMS_MAPPING_POLICY', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/claimsmappingpolicy/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useClaimsByClaimsMappingPolicyId = (
    policyId: any,
    isEnabled: true,
    searchTerm: string = '',
) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'CLAIMS',
            'CLAIMS_MAPPING_POLICY',
            policyId,
            searchTerm,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/claims/byClaimsMappingPolicyId/${policyId}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: isEnabled && Boolean(policyId),
        },
    )
}

export const useApplicationsByClaimsMappingPolicyId = (
    policyId: any,
    isEnabled: true,
    searchTerm: string = '',
) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    return useApiWithInfiniteScroll(
        [APPLICATIONS_KEY, 'APPLICATIONS', 'CLAIMS_MAPPING_POLICY', policyId],
        (skip: any, take: any) => {
            return callApi({
                url: `/api/applications/byAzureClaimsMappingPolicyId/${policyId}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: isEnabled,
        },
    )
}

export const useUnassignClaim = (policyId: any, claimId: any) => {
    const callApi = useAxios()

    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const { refetch: revalidateListing } = useClaimsByClaimsMappingPolicyId(
        policyId,
        true,
    )

    return useMutation(
        () =>
            callApi({
                method: 'PATCH',
                url: `/api/ClaimsMappingPolicy/claim/unassign/${policyId}/${claimId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ClaimDeletedSuccessfully'))
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('Error'))
            },
        },
    )
}

export const useUnassignApplication = (
    policyId: any,
    targetApplicationId: any,
) => {
    const callApi = useAxios()

    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const { refetch: revalidateListing } =
        useApplicationsByClaimsMappingPolicyId(policyId, true)

    return useMutation(
        () =>
            callApi({
                method: 'PATCH',
                url: `/api/ClaimsMappingPolicy/unassign/${policyId}/${targetApplicationId}`,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ApplicationUnassignedSuccessfully'))
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('Error'))
            },
        },
    )
}

export const useAllowedAssigneeTypes = (getData: boolean) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'ALLOwED_ASSIGNEE_TYPE'],
        () =>
            callApi({
                method: 'GET',
                url: `/api/people/allowedAssignmentTypes`,
            }).then((data) => data.data),
        {
            enabled: Boolean(getData),
        },
    )
}

export const useBusinessRolesTree = (getData: boolean, searchTerm: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'BUSINESS_ROLE_TREE', searchTerm],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Lookups/businessRolesTree${
                    searchTerm ? '?searchTerm=' + searchTerm : ''
                }`,
            }).then((data) => data.data),
        {
            enabled: Boolean(getData),
        },
    )
}

export const useBusinessLocationsTree = (getData: boolean, searchTerm: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'BUSINESS_LOCATION_TREE', searchTerm],
        () =>
            callApi({
                method: 'GET',
                url: `/api/Lookups/businessLocationsTree${
                    searchTerm ? '?searchTerm=' + searchTerm : ''
                }`,
            }).then((data) => data.data),
        {
            enabled: Boolean(getData),
        },
    )
}

export const useManagementRolesAll = () => {
    const callApi = useAxios()
    return useMutation(() =>
        callApi({
            method: 'POST',
            url: `/api/ManagementRoles/all`,
            data: {
                skip: 0,
                take: 1000,
            },
        }).then((data) => data.data),
    )
}

export const useApplicationRolesAll = () => {
    const callApi = useAxios()
    return useMutation(() =>
        callApi({
            method: 'POST',
            url: `/api/ApplicationRoles/all`,
            data: {
                skip: 0,
                take: 1000,
            },
        }).then((data) => data.data),
    )
}

export const useManagementRolesAndDefination = () => {
    const callApi = useAxios()
    return useMutation(() =>
        callApi({
            method: 'POST',
            url: `/api/ManagementRoles/managementRoleDefinitions`,
            data: {
                skip: 0,
                take: 1000,
            },
        }).then((data) => data.data),
    )
}

export const useQueryBasedCollection = () => {
    const callApi = useAxios()
    return useMutation(() =>
        callApi({
            method: 'POST',
            url: `/api/common/setGroups`,
            data: {
                skip: 0,
                take: 1000,
            },
        }).then((data) => data.data),
    )
}

export const useClaimsMappingPolicies = (azureApplicationId: string) => {
    const [state]: any = usePageContext()
    const {
        searchTerm,

        sortOrder,
        sortBy,
        status,

        accountStore,
        accountStoreUsageType,
        advancedSearchTags,
    } = state
    const technicalName = state['advancedSearch.forms.technicalName']
    const friendlyName = state['advancedSearch.forms.friendlyName']

    const callApi = useAxios()

    const queryData: any = {}

    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (accountStoreUsageType) {
        queryData.accountStoreUsageTypeId = accountStoreUsageType.id
    }
    if (accountStore) {
        queryData.accountStoreId = accountStore.id
    }

    if (advancedSearchTags) {
        queryData.tags = advancedSearchTags
    }

    const advancedSearch = []
    if (technicalName) {
        advancedSearch.push({
            name: 'Name',
            type: 'string',
            value: technicalName,
        })
    }
    if (friendlyName) {
        advancedSearch.push({
            name: 'FriendlyName',
            type: 'string',
            value: friendlyName,
        })
    }

    queryData.advancedSearch = advancedSearch

    const endpoint = `api/ClaimsMappingPolicy/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_ALL_CLAIMS_MAPPING_POLICIES',
            searchTerm,
            sortBy,
            sortOrder,
            status,
            technicalName,
            friendlyName,
            accountStore,
            accountStoreUsageType,
            advancedSearchTags,
            azureApplicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    azureApplicationId,
                    skip,
                    take,
                },
            })
        },
    )
}
export const useApplicationsOptionalClaims = (
    id: any,
    isEnabled: true,
    queryFilters: any,
) => {
    const callApi = useAxios()
    const queryData: any = {}

    if (queryFilters) {
        queryData.searchTerm = queryFilters
    }

    return useApiWithInfiniteScroll(
        [APPLICATIONS_KEY, 'OPTIONAL_CLAIMS', id, queryFilters],
        (skip: any, take: any) => {
            return callApi({
                url: `api/claims/optional/${id}`,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled: isEnabled,
        },
    )
}

export const useApplicationsOptionalClaimAdditionalProperties = (id: any) => {
    const callApi = useAxios()

    return useApiWithInfiniteScroll(
        [APPLICATIONS_KEY, 'OPTIONAL_CLAIMS', 'ADDITIONAL_PROPERTIES', id],
        (skip: any, take: any) => {
            return callApi({
                url: `api/claims/additionalProperties/${id}`,
                method: 'POST',
                data: {
                    skip,
                    take,
                },
            })
        },
    )
}

export const useAppManagementRoles = (applicationId: any, take?: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    if (take) {
        queryData.take = take
    }
    const endpoint = `api/ManagementRoles/application/${applicationId}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_MANAGEMENT_ROLES',
            sortBy,
            sortOrder,
            searchTerm,
            applicationId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    skip,
                    take,
                    ...queryData,
                },
            })
        },
    )
}
export const useMembersByApplicationId = (
    applicationId: any,
    roleId: any,
    isDirect: boolean,
) => {
    const callApi = useAxios()
    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `/api/ManagementRoles/membersByApplicationId`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_MANAGEMENT_ROLES_MEMBERS_BY_APP_ID',
            sortBy,
            sortOrder,
            searchTerm,
            applicationId,
            roleId,
            isDirect,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    roleId: roleId,
                    applicationId,
                    showDirectAssignedOnly: isDirect,
                },
            })
        },
    )
}
export const useAppManagementRole = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/ManagementRoles/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationAppRightsAssignments = (
    apiEndPoint: any,
    resourceSystemModuleID: any,
    queryFilters = {} as any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state
    //NOTE: Getting roleId below so that we can refresh listings on the base of roleId
    const roleId = apiEndPoint?.split('/').pop()
    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_RIGHT_ASSIGNMENTS',
            apiEndPoint,
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
            resourceSystemModuleID,
            roleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: apiEndPoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    resourceSystemModuleID,
                },
            })
        },
    )
}

export const refreshAppRightsAssignmentsListing = (
    resourceSystemModuleId: any,
    roleId: any,
) => {
    if (resourceSystemModuleId) {
        queryCache.invalidateQueries(
            (c) =>
                c.queryKey.includes(
                    'LIST_MY_APPLICATION_APP_RIGHT_ASSIGNMENTS',
                ) && c.queryKey.includes(resourceSystemModuleId),
        )
    }
    if (roleId) {
        queryCache.invalidateQueries(
            (c) =>
                c.queryKey.includes(
                    'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_APP_RIGHTS',
                ) && c.queryKey.includes(roleId),
        )
        queryCache.invalidateQueries(
            (c) =>
                c.queryKey.includes(
                    'LIST_MY_APPLICATION_APP_RIGHT_ASSIGNMENTS',
                ) && c.queryKey.includes(roleId),
        )
    }
}

export const useDeleteApplicationAppRightsAssignments = (
    resourceSystemModuleId: any,
    roleId: any,
) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (assignments: [any]) =>
            callApi({
                method: 'POST',
                url: `/api/localRights/appRoles/deleteAssignments`,
                data: {
                    localRightAssignmentsIds: assignments.map((a) => {
                        return a.id
                    }),
                },
            }),
        {
            onSuccess: (res) => {
                res?.data.forEach((item: any) => {
                    item?.isSucess
                        ? showSuccessMessage(item.message)
                        : showWarningMessage(item.message)
                })

                refreshAppRightsAssignmentsListing(
                    resourceSystemModuleId,
                    roleId,
                )
            },

            onError: () => {
                showWarningMessage(t('AssignmentNotDeleted'))
            },
        },
    )
}

export const useUnAssignAppRightsFromRoleDefinition = (
    resourceSystemModuleId: any,
    roleId: any,
) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (assignments: [any]) =>
            callApi({
                method: 'POST',
                url: `/api/AzureRoles/unAssignAppRightsFromRoleDefinition`,
                data: {
                    appRightIds: assignments.map((a) => {
                        return a.id
                    }),
                    azureRoleId: roleId,
                },
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('AssignmentDeleted'))
                refreshAppRightsAssignmentsListing(
                    resourceSystemModuleId,
                    roleId,
                )
            },
            onError: () => {
                showWarningMessage(t('AssignmentNotDeleted'))
            },
        },
    )
}

export const useAppLocalRightsByRoleId = (
    roleId: any,
    endpoint: any,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }

    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }
    const url = `${endpoint}/${roleId}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_APP_RIGHTS',
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
            roleId,
            endpoint,
        ],
        (skip: any, take: any) => {
            return callApi({
                url,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}
export const useAppManagementRoleRoleDefinitions = (
    roleId: any,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    const endpoint = `api/ManagementRoles/localRoles/${roleId}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_ROLES_DEFINITIONS',
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
            roleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}
export const useAppManagementRoleAccessGranted = (
    roleId: any,
    resourceTypeId: any,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    const endpoint = `api/AssigneeAssignments/accessGrantedResources/${roleId}/${resourceTypeId}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_RBAC',
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
            roleId,
            resourceTypeId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useAppManagementRoleMembers = (
    roleId: any,
    isDirect: boolean,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    const endpoint = isDirect
        ? `/api/ManagementRoles/members/${roleId}`
        : `/api/ManagementRoles/DirectMembers/${roleId}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_MEMBERS',
            isDirect,
            roleId,
            queryFilters?.searchTerm,
            sortBy,
            sortOrder,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    skip,
                    take,
                    ...queryData,
                },
            })
        },
    )
}
export const useAppManagementRoleEligibility = (roleId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `api/EligibilityAssignments`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_ELIGIBILITY',
            sortBy,
            sortOrder,
            searchTerm,
            roleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    assignmentPointId: roleId,
                },
            })
        },
    )
}
export const useAppManagementRoleSensitiveFunctions = (roleId: any) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `api/RiskFunctions/byAssigneeId/${roleId}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_SENSITIVE_FUNCTIONS',
            sortBy,
            sortOrder,
            searchTerm,
            roleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useEligibilityAssignments = (
    dataObject: any,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    const endpoint = `/api/EligibilityAssignments`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_RIGHT_ELIGIBILITY',
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...dataObject,
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useApplicationAppRightsSensitiveFunctions = (
    id: any,
    url: any,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    const endpoint = `${url}/${id}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_RIGHT_ELIGIBILITY',
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
            id,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useApplicationPeopleByResourceId = (
    resourceId: any,
    apiUrl: string,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters?.searchTerm
    }
    if (queryFilters?.showLocalRoles) {
        queryData.showLocalRoles = true
    }
    if (queryFilters?.showLocalRights) {
        queryData.showLocalRights = true
    }

    const endpoint = apiUrl || `api/people/allPeopleByResource`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_PEOPLE',
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
            resourceId,
            queryFilters?.showLocalRoles,
            queryFilters?.showLocalRights,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    resourceId,
                },
            })
        },
    )
}

export const useApplicationRoleDefinitionsAssignments = (
    roleId: any,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    const endpoint = `api/AzureRoles/assigmentsByRoleDefinition/${roleId}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_ROLE_DEFINITIONS_ASSIGNMENTS',
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
            roleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    roleId,
                },
            })
        },
    )
}

export const useAssignmentsByApplication = (
    resourceSystemModuleId: any,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    const endpoint = `api/AzureRoles/assignmentsByApplication/${resourceSystemModuleId}`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_ASSIGNMENTS',
            sortBy,
            sortOrder,
            queryFilters?.searchTerm,
            resourceSystemModuleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useApplicationRoleDefinitions = (
    resourceId: any,
    resourceSystemModuleId: any,
    queryFilters: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy } = state
    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (queryFilters?.searchTerm) {
        queryData.searchTerm = queryFilters.searchTerm
    }

    const endpoint = `/api/AzureRoles`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_ROLE_DEFINITIONS',
            resourceId,
            resourceSystemModuleId,
            queryFilters.searchTerm,
            sortBy,
            sortOrder,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    applicationId: resourceId,
                    resourceSystemModuleId,
                },
            })
        },
    )
}

export const useApplicationRoleDefinition = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'ROLE_DEFINITION', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/AzureRoles/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}
export const useTotalAccessGrantedByApplication = (
    applicationId: any,
    roleId: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `api/ManagementRoles/accessGrantedByApplicationId`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_TOTAL_ACCESS_GRANTED',
            sortBy,
            sortOrder,
            searchTerm,
            applicationId,
            roleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    applicationId,
                    roleId,
                },
            })
        },
    )
}

// NOTE: Changes will be needed in next development
export const useAssignAppRights = (refetch: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        ({ assigneeId, roleIds }: any) =>
            callApi({
                method: 'POST',
                url: `api/localRights/assignRightsToPerson/${assigneeId}`,
                data: roleIds,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('RightsAssigned'))
                refetch()
                clearModalSearch()
            },
            onError: () => {
                showWarningMessage(t('FailedToAssignRights'))
                clearModalSearch()
            },
        },
    )
}

export const useUnAssignAppRights = (refetch: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        ({ assigneeId, roleIds }: any) =>
            callApi({
                method: 'POST',
                url: `/api/localRights/unasignRightsFromPerson/${assigneeId}`,
                data: roleIds,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('RightsUnAssigned'))
                refetch()
                clearModalSearch()
            },
            onError: () => {
                showWarningMessage(t('FailedToUnAssignRights'))
                clearModalSearch()
            },
        },
    )
}

export const useAssignRoleDefinitionForPeople = (refetch: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        ({ assigneeIds, localRolesToAssign, assigneeLocalRoleScopes }: any) => {
            return callApi({
                method: 'POST',
                url: `api/AzureRoles/AssignRoleDefinitionsForPerson`,
                data: {
                    localRolesToAssign: localRolesToAssign,
                    assigneeIds: assigneeIds,
                    assigneeLocalRoleScopes: assigneeLocalRoleScopes || [],
                },
            })
        },

        {
            onSuccess: (res: any) => {
                res?.data.forEach((item: any) => {
                    item?.isSucess
                        ? showSuccessMessage(item.message)
                        : showWarningMessage(item.message)
                })
                refetch()
                clearModalSearch()
            },
            onError: () => {
                showWarningMessage(t('ErrorOccurred'))
                clearModalSearch()
            },
        },
    )
}

export const useUnassignRoleDefinitionsForPeople = (refetch: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        ({ assigneeIds, localRoleIds, assigneeLocalRoleScopeIds }: any) => {
            return callApi({
                method: 'POST',
                url: `/api/AzureRoles/unAssignRoleDefinition`,
                data: {
                    localRoleIds: localRoleIds,
                    assigneeIds: assigneeIds,
                    assigneeLocalRoleScopeIds: assigneeLocalRoleScopeIds || [],
                },
            })
        },
        {
            onSuccess: () => {
                showSuccessMessage(t('RolesUnassigned'))
                refetch()
                clearModalSearch()
            },
            onError: () => {
                showWarningMessage(t('ErrorOccurred'))
                clearModalSearch()
            },
        },
    )
}

export const usePbacResourceTypes = (
    applicationId: any,
    resourceSystemModuleId: any,
    enabled: boolean,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }
    queryData.applicationId = applicationId
    queryData.resourceSystemModuleId = resourceSystemModuleId

    const endpoint = `api/pbacresourcetypes/all`
    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_PBAC_RESOURCE_TYPES',
            sortBy,
            sortOrder,
            searchTerm,
            applicationId,
            resourceSystemModuleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                },
            })
        },
        {
            enabled,
        },
    )
}

export const usePbacAppResources = (
    applicationId: any,
    resourceSystemModuleId: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `api/PBACAppResources/all`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_PBAC_APP_RESOURCES',
            sortBy,
            sortOrder,
            searchTerm,
            applicationId,
            resourceSystemModuleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    applicationId,
                    resourceSystemModuleId,
                    skip,
                    take,
                },
            })
        },
    )
}

export const useApplicationAppRightsDetails = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'APP_RIGHTS_DETAILS', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/localRights/appRoles/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApplicationAppRightsOwners = (id: any) => {
    const callApi = useAxios()
    return useQuery(
        [APPLICATIONS_KEY, 'APP_RIGHTS_OWNER_LIST', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/resourceOwners?resourceId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}
export const useDirectAccessGrantedByApplication = (
    applicationId: any,
    roleId: any,
) => {
    const callApi = useAxios()

    const [state]: any = usePageContext()
    const { sortOrder, sortBy, searchTerm } = state

    const queryData: any = {}

    if (sortBy) {
        queryData.sortBy = sortBy
    }
    if (sortOrder) {
        queryData.desc = sortOrder === 'desc'
    }
    if (searchTerm) {
        queryData.searchTerm = searchTerm
    }

    const endpoint = `api/ManagementRoles/directAccessGrantedByApplicationId`

    return useApiWithInfiniteScroll(
        [
            APPLICATIONS_KEY,
            'LIST_MY_APPLICATION_APP_MANAGEMENT_ROLES_DIRECT_ACCESS_GRANTED',
            sortBy,
            sortOrder,
            searchTerm,
            applicationId,
            roleId,
        ],
        (skip: any, take: any) => {
            return callApi({
                url: endpoint,
                method: 'POST',
                data: {
                    ...queryData,
                    skip,
                    take,
                    applicationId,
                    roleId,
                },
            })
        },
    )
}
export const useDeleteApplicationRoleDefinition = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (ids) =>
            callApi({
                method: 'POST',
                url: `/api/AzureRoles/deleteAzLocalRoles`,
                data: {
                    azLocalRoleIds: [ids],
                },
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('RoleDeleted'))
                refreshPermissionsListing()
                refreshRoleDefinitionsListing()
            },
            onError: () => {
                showWarningMessage(t('RoleNotDeleted'))
            },
        },
    )
}

export const useUnAssignApplicationRoleDefinition = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (assignments: [any]) =>
            callApi({
                method: 'POST',
                url: `/api/AzureRoles/unAssignRoleDefinition`,
                data: {
                    assigneeLocalRoleScopeIds: assignments.map((a) => {
                        return a.id
                    }),
                },
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('AssignmentDeleted'))
                refreshRoleDefinitionsListing()
            },
            onError: () => {
                showWarningMessage(t('AssignmentNotDeleted'))
            },
        },
    )
}

//Below 2 methods are created to refresh listing generically using AND operator

const includesAll = (queryKey: any, keywords: any) => {
    return keywords.every((keyword: any) => queryKey.includes(keyword))
}
export const refreshGenericListingWithAndOperator = (keywords: any) => {
    if (keywords.length > 0) {
        queryCache.invalidateQueries((c) => includesAll(c.queryKey, keywords))
    }
}

//Note: Below endpoint is already created for sharefolders but now I am creating one for generic use with generic messages

export const useDeleteAssigneeAssignment = (refreshListingKeysArray: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (ids) =>
            callApi({
                method: 'POST',
                url: `/api/AssigneeAssignments/deleteAssignments`,
                data: ids,
            }),
        {
            onSuccess: (res) => {
                const updatedResponse = res?.data
                if (!updatedResponse?.length) {
                    showSuccessMessage(t('AssignmentsDeleted'))
                }
                if (refreshListingKeysArray) {
                    refreshGenericListingWithAndOperator(
                        refreshListingKeysArray,
                    )
                }
            },
            onError: () => {
                showWarningMessage(t('AssignmentsNotDeleted'))
            },
        },
    )
}
export const useCreateOrUpdatePBACAppResource = (
    refreshListingKeysArray: any,
) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/PBACAppResources/createOrUpdatePbacAppResources`,
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ResourceAddedSuccessfully'))
                refreshGenericListingWithAndOperator(refreshListingKeysArray)
            },
            onError: () => {
                showWarningMessage(t('ResourceNotAdded'))
            },
        },
    )
}
export const useDeletePBACAppResource = (refreshListingKeysArray: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (ids) =>
            callApi({
                method: 'POST',
                url: `/api/PBACAppResources/delete`,
                data: ids,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ResourceDeletedSuccessfully'))
                refreshGenericListingWithAndOperator(refreshListingKeysArray)
            },
            onError: () => {
                showWarningMessage(t('ResourceNotDeleted'))
            },
        },
    )
}
export const useCreatePBACAppResourceTypes = (refetch: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/PBACResourceTypes/create`,
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ResourceTypeAddedSuccessfully'))
                refetch()
            },
            onError: () => {
                showWarningMessage(t('ResourceTypeNotAdded'))
            },
        },
    )
}

export const useUpdatePBACAppResourceTypes = (
    resourceTypeId: any,
    refetch: any,
) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    return useMutation(
        (data) =>
            callApi({
                method: 'POST',
                url: `/api/PBACResourceTypes/update/${resourceTypeId}`,
                data,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ResourceTypeUpdatedSuccessfully'))
                refetch()
            },
            onError: () => {
                showWarningMessage(t('ResourceTypeNotUpdated'))
            },
        },
    )
}
export const useDeletePBACAppResourceType = (refetch: any) => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()
    return useMutation(
        (ids) =>
            callApi({
                method: 'POST',
                url: `/api/PBACResourceTypes/delete`,
                data: ids,
            }),
        {
            onSuccess: () => {
                showSuccessMessage(t('ResourceTypeDeletedSuccessfully'))
                refetch()
            },
            onError: () => {
                showWarningMessage(t('ResourceTypeNotDeleted'))
            },
        },
    )
}
