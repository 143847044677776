import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const useUiActionsByResource = (
    resourceId,
    noun = '',
    verb = '',
    isEnabled = true,
) => {
    const callApi = useAxios()

    let endpointUrl = `/api/uiActions/byResourceId?resourceId=${resourceId}`

    if (noun !== '') {
        endpointUrl = `${endpointUrl}&noun=${noun}`
    }
    if (verb !== '') {
        endpointUrl = `${endpointUrl}&verb=${verb}`
    }

    return useQuery(
        ['UI_ACTIONS', resourceId, noun, verb, isEnabled],
        () =>
            callApi({
                method: 'GET',
                url: endpointUrl,
            }).then((data) => data.data),
        {
            enabled: Boolean(resourceId) && isEnabled,
        },
    )
}

export const useUiActionsByNounAndVerb = (noun = '', verb = '') => {
    const callApi = useAxios()

    let endpointUrl = `/api/uiActions/byNoun?noun=${noun}`

    if (verb !== '') {
        endpointUrl = `${endpointUrl}&verb=${verb}`
    }

    return useQuery(
        ['UI_ACTIONS_NOUN', noun, verb],
        () =>
            callApi({
                method: 'GET',
                url: endpointUrl,
            }).then((data) => data.data),
        {
            enabled: Boolean(noun),
        },
    )
}
