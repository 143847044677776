import { getFullPathFromUrl, isNilOrEmpty } from 'packages/core'
import config from 'config'
import i18n from 'i18next'

const statusColors = {
    approved: '#1db57a',
    rejected: '#d0021b',
    open: '#d66a00',
}
export const ApplicationTypes = {
    NotPbacAndNotAzure: 'NotPBACAndNotAzure',
    PbacHasNoAppResources: 'PBACHasNoAppResource',
    PbacHasAppResourcesWithoutFieldType: 'PBACHasAppResourceWithoutFieldType',
    PbacHasAppResourcesWithFieldType: 'PBACHasAppResourceWithFieldType',
    PBACHasNoAppResourceWithFieldType: 'PBACHasNoAppResourceWithFieldType',
    AzureApp: 'AzureApp',
}

export const isApplicationAzureApp = (applicationType: string) => {
    return applicationType
        ? [ApplicationTypes.AzureApp].includes(applicationType)
        : false
}

const DIRECT_ASSIGNED_LOCATIONS_MAX_LIMIT = 5
export const convertListToFriendlyNameCsv = (
    locations: { friendlyName: string }[],
    maxLimit: number = DIRECT_ASSIGNED_LOCATIONS_MAX_LIMIT,
) => {
    let locationsString = ''
    if (!locations) return '-'
    let visibileLocations: { friendlyName: string }[] = locations.slice(
        0,
        maxLimit,
    )
    locationsString = visibileLocations.map((l) => l.friendlyName).join(', ')

    if (locations.length > maxLimit) {
        locationsString += `, +${locations.length - maxLimit} ${i18n.t('More')}`
    }

    return locationsString
}

export const isApplicationPbacApp = (applicationType: string) => {
    return applicationType
        ? [
              ApplicationTypes.PbacHasAppResourcesWithFieldType,
              ApplicationTypes.PbacHasAppResourcesWithoutFieldType,
              ApplicationTypes.PbacHasNoAppResources,
              ApplicationTypes.PBACHasNoAppResourceWithFieldType,
          ].includes(applicationType)
        : false
}

export const isApplicationPbacAppHavingAppResources = (
    applicationType: string,
) => {
    return (
        applicationType ===
            ApplicationTypes.PbacHasAppResourcesWithoutFieldType ||
        applicationType === ApplicationTypes.PbacHasAppResourcesWithFieldType
    )
}

export const subHeaderHeight = 70
export const smallScreenWidth = '1022px'
export const tabletScreenWidth = '768px'
export const mobileScreenWidth = '767px'

export const imagesDisabled =
    config.DISABLE_IMAGES?.toString().toLowerCase() === 'true'

export const getFullImageUrl = (imagePath: any) =>
    !isNilOrEmpty(imagePath) ? `${config.BASE_EID_URL}${imagePath}` : undefined

export const paginate = (items: any, pageNumber: any, pageSize: any) => {
    const startIndex = (pageNumber - 1) * pageSize
    const end = startIndex + pageSize
    items = items.slice(startIndex, end)
    return items
}
export const getStatusColor = (status: any, defaultColor = '#ffffff') => {
    return Object.keys(statusColors).includes(status)
        ? //@ts-ignore
          statusColors[status]
        : defaultColor
}

export const getMasterLockFieldsWidth = (
    headings: any,
    tableHeadingRefs: any,
) => {
    let allRefWidth = 0
    const masterLockFields = headings.filter(
        (x: { showMasterLock: any }) => x.showMasterLock,
    )
    if (masterLockFields?.length > 0) {
        masterLockFields.forEach((item: { name: any }) => {
            const itemIndex = headings.findIndex(
                (x: { name: any }) => x.name === item.name,
            )
            if (itemIndex >= 0) {
                allRefWidth = tableHeadingRefs.current[itemIndex]?.current
                    ?.offsetWidth
                    ? allRefWidth +
                      tableHeadingRefs.current[itemIndex].current.offsetWidth
                    : allRefWidth
            }
        })
    }
    return allRefWidth
}

export const getMasterLockLeftSpace = (
    headings: any,
    tableHeadingRefs: any,
) => {
    let allRefOnLeftWidth = 0
    let keysBeforeMasterPass: any[] = []
    let isMasterLockFound = false
    headings.forEach((element: { showMasterLock: any; name: any }) => {
        if (!element.showMasterLock && !isMasterLockFound) {
            keysBeforeMasterPass.push(element.name)
        } else {
            isMasterLockFound = true
        }
    })
    keysBeforeMasterPass.forEach((item) => {
        const itemIndex = headings.findIndex(
            (x: { name: any }) => x.name === item,
        )
        if (itemIndex >= 0) {
            allRefOnLeftWidth = tableHeadingRefs.current[itemIndex]?.current
                ?.offsetWidth
                ? allRefOnLeftWidth +
                  tableHeadingRefs.current[itemIndex].current.offsetWidth
                : allRefOnLeftWidth
        }
    })
    return allRefOnLeftWidth
}
export const getAppliedFiltersValues = (state: any) => {
    let values: any = []
    const defaultFiltersKeys = [
        'highLevelClassification',
        'groupType',
        'uncPath',
        'businessFunction',
        'location',
        'application',
        'rights',
        'accountStoreUsageType',
        'accountStore',
        'showOnlyAzureApplications',
        'ownedBy',
        'advancedSearch.forms.name',
        'advancedSearch.forms.description',
        'advancedSearch.forms.friendlyName',
    ]
    defaultFiltersKeys.map((key) => {
        if (state[key]) {
            values.push({ [key]: state[key] })
        }
    })
    return values
}

export const compareObjects = (
    obj1: any,
    obj2: any,
    propsToCompare: string[],
) => {
    if (!obj1 || !obj2 || !propsToCompare.length) {
        return []
    }
    const difference: string[] = []
    propsToCompare.forEach((p: string) => {
        if (obj1[p] !== obj2[p]) {
            difference.push(p)
        }
    })

    return difference
}

export const defaultDataProtectedSubcomponent = 'NotProtected'

export function updateUrlQueryParams(
    query: URLSearchParams,
    history: any,
    paramsToSet: any,
    paramsToDelete: any,
) {
    try {
        paramsToSet.forEach((p: any) => {
            query.set(p.key, p.value)
        })
        paramsToDelete.forEach((p: any) => {
            query.delete(p)
        })
        history.push(`${history.location.pathname}?${query.toString()}`)
    } catch (err) {}
}

export function checkTabAccess(
    tabs: any,
    selectedTab: any,
    hasAccessToControl: any,
) {
    try {
        if (!selectedTab) {
            return true
        }
        // Check if matched with any tab or not
        const selectedTabInfo = tabs.find(
            (x: any) =>
                x.value.toLowerCase() === selectedTab.toLowerCase() &&
                x.display,
        )
        if (!selectedTabInfo) {
            return false
        }

        // If Selected value from URL matched with one value and then checkf for access to that tab
        // Case: When tab is not protected
        if (!selectedTabInfo?.requireAccess?.control) {
            return true
        }

        // Case: Check if access is granted or not
        const isAccessible = hasAccessToControl(
            selectedTabInfo?.requireAccess?.control,
        )
        return isAccessible ? true : false
    } catch (err) {}
}

export function getTabsBasedOnAccess(tabs: any, hasAccessToControl: any) {
    try {
        const filteredTabs = tabs.filter((x: any) => {
            if (checkTabAccess(tabs, x.value, hasAccessToControl)) {
                return x
            }
        })

        return filteredTabs?.length ? filteredTabs : []
    } catch (err) {}
}

export function getAccessibleTabs(
    query: any,
    history: any,
    tabs: any,
    urlActiveTab: any,
    urlSubActiveTab: any,
    hasAccessToControl: any,
    urlParentKey: any,
    urlChildKey: any,
) {
    try {
        let queryActiveTab = ''
        let querySubActiveTab = ''
        const accessibleTabs = getTabsBasedOnAccess(tabs, hasAccessToControl)
        const defaultValue = accessibleTabs?.length
            ? accessibleTabs[0].value
            : ''
        const allAccessibleTabs = accessibleTabs?.length ? accessibleTabs : []
        if (
            urlActiveTab &&
            checkTabAccess(tabs, urlActiveTab, hasAccessToControl)
        ) {
            queryActiveTab = urlActiveTab
            // Check for second level
            if (urlSubActiveTab) {
                const tempSubTabs = tabs.find(
                    (x: any) =>
                        x.value.toLowerCase() ===
                        queryActiveTab.toLocaleLowerCase(),
                )
                const isSubTabExist = tempSubTabs?.subTabs?.length
                    ? true
                    : false
                if (
                    isSubTabExist &&
                    !checkTabAccess(
                        tempSubTabs?.subTabs,
                        urlSubActiveTab,
                        hasAccessToControl,
                    )
                ) {
                    // Check if subtab is typed wrongly in URL then redirect to default correct one
                    if (isSubTabExist) {
                        const accessibleSubTabs = getTabsBasedOnAccess(
                            tempSubTabs?.subTabs,
                            hasAccessToControl,
                        )
                        const defaultSubValue = accessibleSubTabs?.length
                            ? accessibleSubTabs[0].value
                            : ''
                        if (defaultSubValue) {
                            querySubActiveTab = defaultSubValue
                            updateUrlQueryParams(
                                query,
                                history,
                                [
                                    {
                                        key: urlParentKey,
                                        value: queryActiveTab,
                                    },
                                    {
                                        key: urlChildKey,
                                        value: defaultSubValue,
                                    },
                                ],
                                [],
                            )
                        } else {
                            queryActiveTab = defaultValue
                            updateUrlQueryParams(
                                query,
                                history,
                                [{ key: urlParentKey, value: defaultValue }],
                                [urlChildKey],
                            )
                        }
                    } else {
                        queryActiveTab = defaultValue
                        updateUrlQueryParams(
                            query,
                            history,
                            [{ key: urlParentKey, value: defaultValue }],
                            [urlChildKey],
                        )
                    }
                } else {
                    querySubActiveTab = urlSubActiveTab
                }
            }
            // Below case is for when we have subTabs in iverview screen
        } else if (!urlActiveTab && urlSubActiveTab) {
            const isTabAccessible = checkTabAccess(
                tabs,
                urlSubActiveTab,
                hasAccessToControl,
            )
            if (!isTabAccessible) {
                const accessibleSubTabs = getTabsBasedOnAccess(
                    tabs,
                    hasAccessToControl,
                )
                const defaultSubValue = accessibleSubTabs?.length
                    ? accessibleSubTabs[0].value
                    : ''
                if (defaultSubValue) {
                    querySubActiveTab = defaultSubValue
                    updateUrlQueryParams(
                        query,
                        history,
                        [{ key: urlChildKey, value: defaultSubValue }],
                        [],
                    )
                } else {
                    queryActiveTab = defaultValue
                    querySubActiveTab = ''
                    updateUrlQueryParams(query, history, [], [urlChildKey])
                }
            } else {
                querySubActiveTab = urlSubActiveTab
                updateUrlQueryParams(
                    query,
                    history,
                    [{ key: urlChildKey, value: urlSubActiveTab }],
                    [],
                )
            }
        } else {
            queryActiveTab = defaultValue
            // Check if any subtab exist
            // This case suppose we are on first tab and having subTbas. Then URL will show subTabs.
            // On navigating to first default the sub tabs remain in URL if the next tab constains the no siub tabs or wrong if contains
            const tempSubTabs = tabs.find(
                (x: any) =>
                    x.value.toLowerCase() === defaultValue.toLocaleLowerCase(),
            )
            const isSubTabExist = tempSubTabs?.subTabs?.length ? true : false
            if (isSubTabExist) {
                const accessibleSubTabs = getTabsBasedOnAccess(
                    tempSubTabs.subTabs,
                    hasAccessToControl,
                )
                const defaultSubValue = accessibleSubTabs?.length
                    ? accessibleSubTabs[0].value
                    : ''
                if (defaultSubValue) {
                    querySubActiveTab = defaultSubValue
                    updateUrlQueryParams(
                        query,
                        history,
                        [
                            { key: urlParentKey, value: defaultValue },
                            { key: urlChildKey, value: defaultSubValue },
                        ],
                        [],
                    )
                } else {
                    updateUrlQueryParams(
                        query,
                        history,
                        [{ key: urlParentKey, value: defaultValue }],
                        [urlChildKey],
                    )
                }
            } else {
                if (queryActiveTab) {
                    updateUrlQueryParams(
                        query,
                        history,
                        [{ key: urlParentKey, value: defaultValue }],
                        [urlChildKey],
                    )
                }
            }
        }

        return {
            activeTab: queryActiveTab,
            querySubActiveTab: querySubActiveTab,
            allAccessibleTabs: allAccessibleTabs,
        }
    } catch (err) {
        return {
            activeTab: '',
            allAccessibleTabs: [],
        }
    }
}

export const handleRenderWorkflow = (
    wfParams: any,
    requestWorkflowName: any,
    history?: any,
) => {
    let queryParamsForNewTab = [
        'wfMsContext=true',
        `wfMsUrl=${
            window.location.origin
        }#w/${requestWorkflowName}?popupNav=true&CloseWindowAfter=0${
            wfParams?.length > 0 ? '&' + wfParams.join('&') : ''
        }`,
    ]
    let queryParamsForEmbeddedWFs = [
        'wfMsContext=true',
        `wfMsUrl=${
            window.location.origin
        }#w/${requestWorkflowName}?popupNav=true${
            wfParams?.length > 0 ? '&' + wfParams.join('&') : ''
        }`,
    ]
    if (config?.EMBEDED_WORKFLOWS === 'false') {
        const queryStringForNewTab = queryParamsForNewTab.join('&')
        window.open(
            `${config.BASE_EID_URL}/ui?hideHeader=true&${queryStringForNewTab}`,
            '_blank',
        )
    } else {
        const queryStringForEmbeddedWFs = queryParamsForEmbeddedWFs.join('&')
        let url = `${config.BASE_EID_URL}/ui?hideHeader=true&${queryStringForEmbeddedWFs}`
        const encodedWorkflowURL = encodeURIComponent(url)
        history.push(
            `${
                history.location.pathname
            }/workflows?workflowName=${requestWorkflowName}&workflowURL=${encodedWorkflowURL}&returnUrl=${encodeURIComponent(
                getFullPathFromUrl(),
            )}`,
        )
    }
}
