import {
    Box,
    Checkbox,
    makeStyles,
    Typography,
    useTheme,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import classNames from 'classnames'
import AddAssignment from 'components/ApplicationBanner/AddAssignments/AddAssignment'
import { Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'
import { PageContextProvider } from 'pageContext'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    Applicationroot: {
        width: '100%',

        '& p': {
            fontSize: '12px',
            color: 'rgb(180, 180, 180)',
            textTransform: 'uppercase',
            marginBottom: '10px',
        },
    },
    Applicationinner: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    Checkboxcontainer: {
        borderBottom: '1px solid #efeeee',
        width: '31%',
        padding: '6px 15px',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        '&:hover': {
            background: '#fafcff',
            borderLeft: '3px solid #01539c',
            boxSizing: 'border-box',
        },
        '& p': {
            color: '#01539c',
            fontSize: '12px',
            fontWeight: '600',
            display: 'inline-block',
            margin: '0 5px',
        },
    },
    Checkboxcontaineractive: {
        background: '#fafcff',
        borderLeft: '3px solid #01539c',
        boxSizing: 'border-box',
    },
    nonCheckedIcon: {
        '& svg': {
            opacity: '0.2',
        },
    },
})

const PeopleApplicationList = ({
    personDetails,
    handleApplicationChange,
    bindAssignedPeopleList,
    selectedApplicationList = [],
    data,
    isLoading,
}) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const classes = useStyles()
    const [selectedApplication, setSelectedApplication] = useState(null)

    const getCheckboxStyles = (element) => {
        if (!element) {
            return {
                color: theme?.palette?.primary?.main,
                borderColor: theme?.palette?.primary?.main,
                isChecked: false,
            }
        }

        let assignedItem = []
        let unAssignedItem = []

        const assigned = element.selectedPeopleList.map((item) => item.id)
        const unAssigned = element.alreadyAssignedList.map((item) => item.id)
        assignedItem = [...assignedItem, ...assigned]
        unAssignedItem = [...unAssignedItem, ...unAssigned]

        const uniqueAssignedIds = assignedItem.filter((id, index) => {
            return assignedItem.indexOf(id) === index
        })
        const uniqueUnAssignedIds = unAssignedItem.filter((id, index) => {
            return unAssignedItem.indexOf(id) === index
        })

        const filteredAssignedOnly = uniqueAssignedIds.filter(
            (item) => !uniqueUnAssignedIds.includes(item),
        )
        const filteredUnAssignedOnly = uniqueUnAssignedIds.filter(
            (item) => !uniqueAssignedIds.includes(item),
        )

        if (
            filteredAssignedOnly.length < 1 &&
            filteredUnAssignedOnly.length > 0
        ) {
            return {
                color: 'transparent',
                border: '1px solid orange',
                isChecked: false,
            }
        }

        if (filteredAssignedOnly.length > 0) {
            return {
                color: 'orange',
                border: '',
                isChecked: true,
            }
        }
        return {
            color: theme?.palette?.primary?.main,
            border: '',
            isChecked: false,
        }
    }

    const onSubmit = (selectedRights, alreadyAssignedList, item) => {
        const dataObject = {
            applicationId: item.id,
            selectedPeopleList: selectedRights,
            alreadyAssignedList: alreadyAssignedList,
        }
        handleApplicationChange(dataObject)
    }

    return (
        <Box className={classes.Applicationroot}>
            {isLoading ? (
                <Fragment>
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                    <Skeleton height={20} />
                </Fragment>
            ) : (
                <Fragment>
                    <Typography> {t('Applications')}</Typography>
                    <Box className={classes.Applicationinner}>
                        {data?.map((item, index) => {
                            const colorObj = getCheckboxStyles(
                                selectedApplicationList.find(
                                    (a) => a.applicationId === item.id,
                                ),
                            )
                            return (
                                <Box
                                    key={`application-list-${index}`}
                                    className={classNames(
                                        classes.Checkboxcontainer,
                                        {
                                            [classes.Checkboxcontaineractive]:
                                                item.isLocalRoleAssigned,
                                        },
                                    )}
                                >
                                    <Checkbox
                                        icon={
                                            <Box
                                                className={
                                                    classes.nonCheckedIcon
                                                }
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Icon name="CheckBox" />
                                            </Box>
                                        }
                                        checkedIcon={
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Icon
                                                    name="CheckedBox"
                                                    color={colorObj.color}
                                                    style={{
                                                        border: colorObj.border,
                                                    }}
                                                />
                                            </Box>
                                        }
                                        checked={
                                            item.isLocalRoleAssigned ||
                                            colorObj.isChecked
                                        }
                                    />
                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            setSelectedApplication(item)
                                        }
                                    >
                                        <Tooltip title={item?.friendlyName}>
                                            <Typography
                                                style={{
                                                    maxWidth: '200px',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {item?.friendlyName}
                                            </Typography>
                                        </Tooltip>
                                        <Icon name="Launch" />
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Fragment>
            )}

            {selectedApplication && (
                <PageContextProvider
                    key={'Applications' + selectedApplication?.id}
                >
                    <AddAssignment
                        resourceType={'Applications'}
                        submitButtonLabelKey={'Save'}
                        headerText={{
                            dynamicText: selectedApplication?.friendlyName,
                            text: t('ChooseApplicationRole'),
                        }}
                        searchUrl={`/api/AzureRoles/GetEligibleAppRolesByApplicationByAssignee`}
                        allowRemovingAssignments={true}
                        targetAssigneeId={selectedApplication?.id}
                        searchPlaceHolder="SearchApplicationRoles"
                        queryParams={{
                            applicationId: selectedApplication?.id,
                            eligibilityAssigneeId: personDetails.id,
                            resourceSystemModuleId:
                                selectedApplication?.resourceSystemModuleID,
                        }}
                        listLabels={{
                            unselectedListLabel: 'PeopleApplicationRoles',
                            selectedListLabel: 'SelectedApplicationRoles',
                        }}
                        labelOnly={true}
                        onSubmit={(selectedRights, alreadyAssignedList) =>
                            onSubmit(
                                selectedRights,
                                alreadyAssignedList,
                                selectedApplication,
                            )
                        }
                        submitLoader={false}
                        assignedPeopleList={bindAssignedPeopleList(
                            selectedApplication,
                        )}
                        closeOnSubmit={true}
                        showAssignmentModal={selectedApplication ? true : false}
                        handleClose={() => setSelectedApplication(null)}
                        addSelectedOnly={true}
                        showAccessRequestPolicy={true}
                        fetchRequestPolicyItemWise={true}
                        requestPolicyIdProperty="requestPolicyId"
                        showStartDate={false}
                    />
                </PageContextProvider>
            )}
        </Box>
    )
}

export default PeopleApplicationList
