import { FC } from 'react'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { isNilOrEmpty } from 'packages/core'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers'

export interface StartDateTimePickerProps {
    selectedStartDate: any
    setSelectedStartDate: any
}

export const StartDateTimePicker: FC<StartDateTimePickerProps> = ({
    selectedStartDate,
    setSelectedStartDate,
}) => {
    const { t } = useTranslation()

    return (
        <NativeDateTimePicker
            minDate={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
            value={moment(selectedStartDate)
                .clone()
                .local()
                .format('YYYY-MM-DDTHH:mm')}
            handleChange={(e: any) => {
                if (isNilOrEmpty(e)) {
                    setSelectedStartDate(moment().utc().format())
                } else {
                    const date = moment(e)
                    const dateToSet = date.clone().utc().format()
                    setSelectedStartDate(dateToSet)
                }
            }}
            pickerStyles={{ top: '22px', right: '10px', width: 'auto' }}
            customTimeControl={false}
            disabledTimeSlots={[]}
            clearBtnLabel={t('Clear')}
            todayBtnLabel={t('Today')}
        >
            <TextField
                type="text"
                value={
                    selectedStartDate
                        ? moment(selectedStartDate).local().format('L LT')
                        : ''
                }
                label={t('StartDateTime')}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </NativeDateTimePicker>
    )
}
