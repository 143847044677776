import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { Collapse, Box, styled } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import DomPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
    useGetControlsAccess,
} from 'packages/core'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'

import {
    AppLayout,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
    Button,
} from 'components'
import { usePageContext } from 'pageContext'
import { SubPathLevel } from 'appConfiguration'
import { useMailboxes } from 'hooks/mailBoxesHooks'
import EidTableGrid from 'components/EidTableGrid/EidTableGrid'
import ViewSwitcher from 'components/ViewSwitcher'
import { UIActionsGearButton } from 'components/UIActions/UIActionsGearButton'
import { MailboxDetails } from './MailboxDetails'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'

const TableOverView = styled(Box)({
    '& table': {
        boxShadow: '0 4px 17px 0 rgba(0, 0, 0, 0.07)',
    },
})

const MailboxesLayout = ({ filters }) => {
    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [filterWidth, setFilterWidth] = useState(0)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        rest,
    } = useMailboxes()

    const attributes = [
        {
            name: 'friendlyName',
            sortable: true,
            sortBy: 'friendlyName',
            label: 'FriendlyName',
            hideValue: 'true',
            isHeader: true,
            showInCard: false,

            component: {
                componentName: 'HighlightedClickableLabel',
                queryParam: 'mailboxId',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
                fontWeight: 'bold',
            },
        },
        {
            label: 'TechnicalName',
            name: 'name',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            label: 'Alias',
            name: 'alias',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            label: 'Email',
            name: 'primaryEmailAddress',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            label: 'ResourceSystem',
            name: 'resourceSystemFriendlyName',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            label: 'MailboxType',
            name: 'mailboxType',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },
        {
            label: 'EmpowerIDLogin',
            name: 'empowerIdLogIn',
            sortable: true,
            headerCellStyles: {
                fontSize: '13px',
            },
            styles: {
                color: '#282828',
                fontSize: '13px',
            },
        },

        {
            showInCard: false,

            hideValue: true,
            component: {
                componentName: 'renderer',
                render: (data) => {
                    return (
                        <Box display="flex">
                            <Button.Details
                                onClick={() => {
                                    history.push(`?mailboxId=${data?.id}`)
                                }}
                            />
                            <span style={{ marginRight: '10px' }} />
                            <UIActionsGearButton
                                item={data}
                                resourcePath="mailboxes"
                            />
                        </Box>
                    )
                },
            },
        },
    ]

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )
    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])

    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const query = useQuery()
    const mailBoxId = query.get('mailboxId')
    const showDetailsPage = Boolean(mailBoxId)

    const controls = useGetControlsAccess()
    useEffect(() => {
        if (!mailBoxId) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: null,
            })
        }
    }, [mailBoxId])
    const hasAccessToParsedHtmlMoreInfo =
        controls.findIndex(
            (c) => c.name === 'ResourceAdmin-ParsedHtmlMoreInformation-Control',
        ) >= 0

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let parsedHtmlMoreInfo
    try {
        parsedHtmlMoreInfo = ReactHtmlParser(
            DomPurify.sanitize(t('Mailbox_MoreInfo_Html'), {
                ADD_ATTR: ['target'],
            }),
        )
    } catch (error) {}

    let detailContent
    if (mailBoxId) {
        detailContent = (
            <>
                {isSmallScreen ? (
                    <></>
                ) : (
                    <DesktopContainer>
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <MailboxDetails mailBoxId={mailBoxId} />
                        </Box>
                    </DesktopContainer>
                )}
            </>
        )
    }

    let listingContent
    listingContent = (
        <>
            {isSmallScreen ? (
                <SmallScreenContainer>
                    <Box display={showFilters ? '' : 'none'}>
                        {mobileFiltersScreen}
                    </Box>

                    <Box display={!showFilters ? '' : 'none'}>
                        <Box display={''}>
                            <StickyContainer>
                                <Box width="100%">
                                    {mobileStickyContentListing}
                                </Box>
                            </StickyContainer>
                            <TableOverView>
                                <EidTableGrid
                                    rowSelection={false}
                                    selected={[]}
                                    noDataMessage={t('NoDataMessage')}
                                    pagination={pagination}
                                    isLoading={isLoading}
                                    isLoadingMore={isLoadingMore}
                                    attributes={attributes}
                                    list={list}
                                />
                            </TableOverView>
                        </Box>
                    </Box>
                </SmallScreenContainer>
            ) : (
                <DesktopContainer>
                    <Fragment>
                        {filters && filters.length > 0 && (
                            <FiltersColumn ref={filterContainer}>
                                <FilterContainer>
                                    <Box
                                        padding="16px 0px 0px 0px"
                                        display="flex"
                                    >
                                        <Box width="100%">
                                            <Card.CardHeader
                                                collapsible
                                                fontColor="black"
                                                cardTitle={t('Show_My_Filters')}
                                                handleExpandClick={() =>
                                                    setOpenFilters(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                expanded={openFilters}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                                style={styles.filterCardHeader}
                                            />
                                        </Box>
                                    </Box>
                                </FilterContainer>

                                <Collapse
                                    in={openFilters}
                                    timeout={300}
                                    unmountOnExit
                                >
                                    {hasAccessToParsedHtmlMoreInfo &&
                                        parsedHtmlMoreInfo && (
                                            <Box
                                                style={{
                                                    margin: '0 0 15px 0px',
                                                }}
                                            >
                                                <Card
                                                    cardTitle={t(
                                                        'More_Information',
                                                    )}
                                                    icon={
                                                        <Icon name="MoreInfo" />
                                                    }
                                                    collapsible
                                                    expanded
                                                    disabled={mailBoxId}
                                                >
                                                    <Box
                                                        padding="0px 10px"
                                                        maxWidth="350px"
                                                        overflow="scroll"
                                                    >
                                                        {parsedHtmlMoreInfo}
                                                    </Box>
                                                </Card>
                                            </Box>
                                        )}
                                    <>
                                        {filters.map((f) => {
                                            const FilterToRender =
                                                Filters.filtersMap[f.name]
                                            return (
                                                <FilterContainer
                                                    data-protectedsubcomponent={
                                                        f.requireAccess
                                                    }
                                                    key={f.name}
                                                >
                                                    <FilterToRender />
                                                </FilterContainer>
                                            )
                                        })}
                                    </>
                                </Collapse>
                                <ListingPageUIActionByNounAndVerb
                                    noun="ResourceAdmin"
                                    verb="Mailbox"
                                />
                            </FiltersColumn>
                        )}

                        <ListingGrid>
                            <Box padding="16px 0px 0px 0px" display="flex">
                                <Box width="100%">
                                    <Filters.TextSearch />
                                </Box>
                            </Box>
                            <GridContainer
                                filterWidth={filterWidth}
                                openFilters={openFilters}
                            >
                                <TableOverView>
                                    <EidTableGrid
                                        rowSelection={false}
                                        selected={[]}
                                        noDataMessage={t('NoDataMessage')}
                                        pagination={pagination}
                                        isLoading={isLoading}
                                        isLoadingMore={isLoadingMore}
                                        attributes={attributes}
                                        list={list}
                                        onClickDetailButton={(item) => {
                                            history.push(
                                                `?mailboxId=${item?.id}`,
                                            )
                                        }}
                                    />
                                </TableOverView>
                            </GridContainer>
                        </ListingGrid>
                    </Fragment>
                </DesktopContainer>
            )}
        </>
    )

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={ViewSwitcher}
            showNavTabs={!showDetailsPage}
            showBackButton={showDetailsPage}
        >
            <Fragment>
                {showDetailsPage ? detailContent : listingContent}
            </Fragment>
        </AppLayout>
    )
}

export default MailboxesLayout
