import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
    useCallback,
} from 'react'
import { Collapse, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import attributes from './attributes'
import GroupCard from './GroupCard'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import {
    useIsSmallScreenFalse,
    useQuery,
    useSessionStorage,
    useGetControlsAccess,
} from 'packages/core'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { useHistory } from 'react-router'
import StickyContainer from 'components/StickyContainer'
import {
    GroupsTable,
    GroupsTableHead,
    GroupsRow,
} from './GroupsTableComponents'
import {
    AppLayout,
    useEidGrid,
    CardViewLoader,
    MobileStickyContentListing,
    MobileFiltersScreen,
    Filters,
    DesktopContainer,
    GridContainer,
    FiltersColumn,
    FilterContainer,
    ListingGrid,
    styles,
    SmallScreenContainer,
} from 'components'

import { useGroup, useApplicationSettings, useGroups } from 'hooks'
import { usePageContext } from 'pageContext'
import { GroupDetails } from './GroupsDetails'
import { SubPathLevel } from 'appConfiguration'
import useSubcomponents from 'useSubcomponents'
import config from 'config'
import ListingPageUIActionByNounAndVerb from 'components/UIActionsListingPage/UIActionByNounAndVerb'

const GroupsLayout = ({ filters }) => {
    const filterContainer = useRef(null)
    const { t } = useTranslation()
    const [state, dispatch] = usePageContext()
    const [filterWidth, setFilterWidth] = useState(0)
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const {
        list,
        totalCount,
        loading: isLoading,
        loadingMore: isLoadingMore,
        pagination,
        rest,
    } = useGroups()

    const [openFilters, setOpenFilters] = useSessionStorage(
        'FILTERS_EXPANDED',
        true,
    )

    const handleWindowResize = useCallback(() => {
        setWindowSize(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize])
    useEffect(() => {
        setFilterWidth(filterContainer?.current?.offsetWidth)
    }, [openFilters, windowSize])

    useEffect(() => {
        if (rest && state.shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest])

    const isSmallScreen = useIsSmallScreenFalse()

    const defaultView = isSmallScreen ? 'card' : 'table'
    const { tableAttributes, detailAttributes } = attributes
    const { getAccessBasedAttributes } = useSubcomponents()
    const accessBasedAttributesForGroups =
        getAccessBasedAttributes(tableAttributes)
    const { viewSwitcher, setCurrentView, viewToRender } = useEidGrid({
        isLoading,
        data: list,
        isLoadingMore,
        noDataMessage: t('No_AppRoles_Message'),
        pagination,
        attributes: accessBasedAttributesForGroups,
        cardViewConfig: {
            LoadingIndicatorComponent: CardViewLoader,
            CardComponent: GroupCard,
        },
        tableViewConfig: {
            TableComponent: GroupsTable,
            TableHeadComponent: GroupsTableHead,
            TableRowComponent: GroupsRow,
        },
        defaultView,
    })

    useEffect(() => {
        if (isSmallScreen) {
            setCurrentView('card')
        }
    }, [isSmallScreen])

    const query = useQuery()
    const controls = useGetControlsAccess()

    const hasAccessToCreateAzureApplicationWorkflow =
        controls.findIndex(
            (c) =>
                c.name ===
                'ResourceAdmin-CreateAzureApplicationWorkflow-Control',
        ) >= 0

    const workflowName = query.get('workflow')
    const groupId = query.get('groupId')
    const { data: appConfig } = useApplicationSettings()
    const { staticWorkflows } = appConfig
    const onboardAzureApplicationWF =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w) => w.name === 'OnboardAzureApplication',
              )
            : []
    const { data: group } = useGroup(groupId)

    useEffect(() => {
        if (!groupId) {
            dispatch({
                type: 'SET_CURRENT_RESOURCE',
                payload: null,
            })
        }
    }, [groupId])

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const areaName = pathname.split('/')[1 + SubPathLevel]
    const routeName = pathname.split('/')[2 + SubPathLevel]

    const showFilters = query.get('filters') === 'visible'

    const wfParams = []
    const detailPageTabs = [
        {
            label: t('Overview'),
            value: 'overview',
            icon: <Icon name="Overview" />,
            display: true,
        },
        {
            label: t('Members'),
            value: 'members',
            icon: <Icon name="ViewProfile" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-Members',
            },
        },
        {
            label: t('MembershipChanges'),
            value: 'membershipchanges',
            icon: <Icon name="MembershipChanges" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-MembershipChanges',
            },
        },
        {
            label: t('ResultantMembers'),
            value: 'resultantmembers',
            icon: <Icon name="ResultantMembers" />,
            display: true,
            requireAccess: {
                control: 'ResourceAdmin-Group-Details-ResultantMembers',
            },
        },
    ]
    if (group) {
        wfParams.push(`SelectedResourceID=${group.resourceId}`)
        if (isSmallScreen) {
            return (
                <AppLayout
                    totalCount={totalCount}
                    showBackButton={Boolean(group)}
                >
                    <GroupDetails
                        tabs={detailPageTabs}
                        groupId={groupId}
                        basicAttributes={detailAttributes}
                    />
                </AppLayout>
            )
        }
        return (
            <AppLayout
                totalCount={totalCount}
                showNavTabs={!groupId}
                showBackButton={Boolean(group)}
            >
                <DesktopContainer>
                    {
                        <Box
                            paddingRight={'8px'}
                            marginBottom="16px"
                            width="100%"
                        >
                            <GroupDetails
                                tabs={detailPageTabs}
                                groupId={groupId}
                                basicAttributes={detailAttributes}
                            />
                        </Box>
                    }
                </DesktopContainer>
            </AppLayout>
        )
    }

    const mobileFiltersScreen = (
        <MobileFiltersScreen
            totalCount={totalCount}
            filters={filters}
            handleApplyFilters={() => {
                query.delete('filters')
                history.push(`${history.location.pathname}?${query.toString()}`)
            }}
        />
    )
    const mobileStickyContentListing = (
        <>
            <MobileStickyContentListing
                totalCount={totalCount}
                title={areaName}
                subTitle={routeName}
                handleApplyFilters={() => {
                    query.set('filters', 'visible')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )

    let content
    if (isSmallScreen) {
        content = (
            <SmallScreenContainer>
                <Box display={showFilters ? '' : 'none'}>
                    {mobileFiltersScreen}
                </Box>

                <Box display={!showFilters ? '' : 'none'}>
                    <Box display={''}>
                        <StickyContainer>
                            <Box width="100%">{mobileStickyContentListing}</Box>
                        </StickyContainer>
                        {viewToRender}
                    </Box>
                </Box>
            </SmallScreenContainer>
        )
    } else {
        content = (
            <DesktopContainer>
                {groupId ? (
                    <Box paddingRight={'8px'} marginBottom="16px" width="100%">
                        <GroupDetails groupId={groupId} />
                    </Box>
                ) : (
                    <Fragment>
                        {filters && filters.length > 0 && (
                            <FiltersColumn ref={filterContainer}>
                                <FilterContainer>
                                    <Box
                                        padding="16px 0px 0px 0px"
                                        display="flex"
                                    >
                                        <Box width="100%">
                                            <Card.CardHeader
                                                collapsible
                                                fontColor="black"
                                                cardTitle={t('Show_My_Filters')}
                                                handleExpandClick={() =>
                                                    setOpenFilters(
                                                        (prev) => !prev,
                                                    )
                                                }
                                                expanded={openFilters}
                                                icon={
                                                    <Icon
                                                        name="AdvancedSearch"
                                                        color="#959598"
                                                    />
                                                }
                                                style={styles.filterCardHeader}
                                            />
                                        </Box>
                                    </Box>
                                </FilterContainer>

                                <Collapse
                                    in={openFilters}
                                    timeout={300}
                                    unmountOnExit
                                >
                                    {!groupId &&
                                        hasAccessToCreateAzureApplicationWorkflow &&
                                        onboardAzureApplicationWF.length >
                                            0 && (
                                            <Box>
                                                <EidWorkflowButton
                                                    title={t(
                                                        onboardAzureApplicationWF[0]
                                                            .displayName_LocaleKey,
                                                    )}
                                                    name={
                                                        onboardAzureApplicationWF[0]
                                                            .requestWorkflowName
                                                    }
                                                    path={`/groups/workflows`}
                                                    disabled={workflowName}
                                                />
                                            </Box>
                                        )}

                                    <>
                                        {filters.map((f) => {
                                            const FilterToRender =
                                                Filters.filtersMap[f.name]
                                            return (
                                                <FilterContainer
                                                    data-protectedsubcomponent={
                                                        f.requireAccess
                                                    }
                                                    key={f.name}
                                                >
                                                    <FilterToRender />
                                                </FilterContainer>
                                            )
                                        })}
                                    </>
                                    <ListingPageUIActionByNounAndVerb
                                        noun="ResourceAdmin"
                                        verb="Group"
                                    />
                                </Collapse>
                            </FiltersColumn>
                        )}

                        <ListingGrid>
                            <Box padding="16px 0px 0px 0px" display="flex">
                                <Box width="100%">
                                    <Filters.TextSearch />
                                </Box>
                                {/* More Filters Here */}
                            </Box>
                            <GridContainer
                                filterWidth={filterWidth}
                                openFilters={openFilters}
                            >
                                {viewToRender}
                            </GridContainer>
                        </ListingGrid>
                    </Fragment>
                )}
            </DesktopContainer>
        )
    }

    return (
        <AppLayout
            totalCount={totalCount}
            viewSwitcher={viewSwitcher}
            showNavTabs={true}
        >
            <Fragment>{content}</Fragment>
        </AppLayout>
    )
}

export default GroupsLayout
