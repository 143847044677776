import React, { useState } from 'react'
import { Loader, Tooltip } from 'packages/eid-ui'
import {
    MenuItem,
    ListItemText,
    ListItemIcon,
    Paper,
    Typography,
    styled,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { Icon } from 'packages/eid-icons'
import { useUiActionsByNounAndVerb } from 'hooks'
import { useTranslation } from 'react-i18next'
import { handleRenderWorkflow } from 'utils'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: '16px',
    position: 'relative',
    minHeight: '40px',
    borderLeft: '2px solid transparent',
    '&:hover': {
        backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        borderLeft: `2px solid ${theme?.palette?.primary?.main}`,
        '& svg': {
            opacity: 1,
            filter: 'grayscale(0%)',
            color: 'blue',
        },
    },
    '& span': {
        width: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: '20px !important',
    color: 'black',
    '& svg': {
        height: '16px',
        width: '16px',
    },
}))

const NoActionMessage = styled(Typography)(({ theme }) => ({
    padding: '6px 30px',
    minHeight: '40px',
    textAlign: 'center',
}))

const StyledHeaderMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: '16px',
    position: 'relative',
    minHeight: '60px !important',
    borderLeft: '2px solid transparent',
    borderRadius: '4px',
    '&.Mui-selected': {
        backgroundColor: `${theme?.palette?.primary?.main} !important`,
        color: '#fff',
    },
}))

const StyledHeaderListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: '40px',
    color: 'black',
    '& svg': {
        height: '18px',
        width: '18px',
    },
}))

const ActionsList = (props) => {
    const { noun = 'ResourceAdmin', verb = '' } = props
    const { t } = useTranslation()

    const { data, isLoading } = useUiActionsByNounAndVerb(noun, verb)

    const history = useHistory()

    const handleClick = (name) => {
        handleRenderWorkflow(null, name, history)
    }

    return (
        <>
            <Paper style={{ borderRadius: '8px' }}>
                {isLoading ? (
                    <Loader />
                ) : data && data.length === 0 ? (
                    <NoActionMessage>{t('NoActionFound')}</NoActionMessage>
                ) : (
                    data &&
                    data.map((d, index) => (
                        <StyledMenuItem
                            key={d.id + index}
                            onClick={() => handleClick(d.requestWorkflowName)}
                        >
                            <StyledListItemIcon>
                                <Icon name="Arrow" direction="right" />
                            </StyledListItemIcon>
                            <Tooltip title={d.friendlyName}>
                                <ListItemText>{d.friendlyName}</ListItemText>
                            </Tooltip>
                        </StyledMenuItem>
                    ))
                )}
            </Paper>
        </>
    )
}

const ListingPageUIActionByNounAndVerb = (props) => {
    const { noun, verb } = props
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const header = (
        <Paper style={{ marginBottom: '5px' }}>
            <StyledHeaderMenuItem
                selected={open}
                onClick={() => {
                    setOpen((prev) => !prev)
                }}
            >
                <StyledHeaderListItemIcon>
                    <Icon name="Actions" color={open ? 'white' : 'black'} />
                </StyledHeaderListItemIcon>
                <ListItemText>{t('Actions')}</ListItemText>

                <Typography style={{ display: 'inherit' }}>
                    <Icon
                        name="Arrow"
                        direction="down"
                        color={open ? 'white' : 'black'}
                    />
                </Typography>
            </StyledHeaderMenuItem>
        </Paper>
    )

    return (
        <>
            {header}
            {open && <ActionsList noun={noun} verb={verb} />}
        </>
    )
}

export default ListingPageUIActionByNounAndVerb
